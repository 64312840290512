import React from 'react'
import {Avatar, AvatarGroup, ConversationHeader} from "@chatscope/chat-ui-kit-react";

const ConversationMessageHeader = (props) => {

    const ConversationHeaderStyle = {
        display: "flex",
        color: 'rgba(0,0,0,.87)',
        backgroundColor: '#f6fbff',
        fontFamily: 'Helvetica Neue,Segoe UI,Helvetica,Arial,sans-serif',
        fontsize: '1em',
        flexShrink: 0,
        userSelect: 'non',
        flexDirection: 'row',
        alignItems: 'stretch',
        borderTop: 'solid 0px #d1dbe3',
        borderRight: 'solid 0px #d1dbe3',
        borderBottom: 'solid 1px #d1dbe3',
        borderLeft: 'solid 0px #d1dbe3',
        padding: '.6em .9em'
    }

    return (
        <>
            {props.selectedChat.isGroupChat ? (
                <div as='ConversationHeader' style={ConversationHeaderStyle}>
                    <ConversationHeader.Content
                        style={{order: 0}}
                        userName= {props.selectedChat.chatName}
                        info="Active"
                    />
                    <AvatarGroup max={5}>
                        {props.selectedChat.users.map((u) => {
                            return (
                                <Avatar
                                    src={u.profilepicture}
                                    key={u.fullName}
                                />
                            )
                        })}
                    </AvatarGroup>
                </div>
                ): (
                <ConversationHeader>
                    <Avatar
                        src={props.getSender(props.selectedChat.users).profilepicture}
                        name= {props.getSender(props.selectedChat.users).fullName}
                    />
                    <ConversationHeader.Content
                        userName= {props.getSender(props.selectedChat.users).fullName}
                        info="Active"
                    />
                </ConversationHeader>
            )}
        </>
    )
}

export default ConversationMessageHeader;