import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import axios from "axios";
import LykioButton from "../common/LykioButton";
import Form from "react-bootstrap/Form";
import {
  AUTHORIZED_URL_PREFIX,
  EDIT_PROFILE_URL,
  PROFILE_URL_WITHOUT_PARAM,
  FOLLOW_USER_URL,
  NOTIFICATION_SEVERITY_SUCCESS,
  AxiosConfig,
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import ActiveGroup from "../common/ActiveGroup";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "./profile.css";

const ProfileImageArea = (props) => {
  const userId = props.userId;
  const user = props.user;
  const contextUser = props.contextUser;
  const [subtext, setSubtext] = useState("");
  const [group , setGroup ] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [showFollow, setShowFollow] = useState(true);
  // form stuff
  const [showEditModal, setShowEditModal] = useState(false);
  const [userDescription, setUserDescription] = useState("");
  const [file, setFile] = useState();

  useEffect(() => {
    const fetchProfileData = async () => {
      console.log("useEffect runs");
      try {
        const { data } = await axios.post(
          `${AUTHORIZED_URL_PREFIX}${PROFILE_URL_WITHOUT_PARAM}`,
          { userId },
          AxiosConfig()
        );
        if (contextUser) {
          if (data.user._id === contextUser.id) {
            setIsMyProfile(true);
          } else {
            console.log("contextUser.following", contextUser.following);
            contextUser.following.includes(data.user._id)
              ? setShowFollow(false)
              : setShowFollow(true);
          }
        } else {
          setIsMyProfile(false);
        }
        setSubtext(
          data.user.tenant.groups.filter(
            (group) => group._id === data.user.group
          ).length > 0
            ? `${data.user.description} @ ${data.user.department ? `${data.user.department},` : ''}`
            : `${data.user.description}`
        );
        setGroup(data.user.tenant.groups.filter(
          (group) => group._id === data.user.group
        )[0])

        setUserDescription(data.user.description);
        if (!data.success) {
          notify(pleaseRefreshError);
        }
      } catch (error) {
        console.log(error);
        notify(pleaseRefreshError);
      }
    };
    fetchProfileData();
  }, [isMyProfile]);

  const followUserHandler = async () => {
    try {
      console.log("follow button clicked");
      const { data } = await axios.post(
        `${AUTHORIZED_URL_PREFIX}${FOLLOW_USER_URL}`,
        { userId: user._id, follow: true },
        AxiosConfig()
      );
      setShowFollow(false);
      if (!data.success) {
        notify(pleaseRefreshError);
      }
    } catch (error) {
      console.log(error);
      notify(pleaseRefreshError);
    }
  };

  const unfollowUserHandler = async () => {
    try {
      console.log("unfollow button clicked");
      const { data } = await axios.post(
        `${AUTHORIZED_URL_PREFIX}${FOLLOW_USER_URL}`,
        { userId: user._id, follow: false },
        AxiosConfig()
      );
      setShowFollow(true);
      if (!data.success) {
        notify(pleaseRefreshError);
      }
    } catch (error) {
      console.log(error);
      notify(pleaseRefreshError);
    }
  };

  const updateUserProfileHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("userDescription", userDescription);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("postcode", postcode);
      formData.append("country", country);
      formData.append("phone", phone);
      formData.append("gender", gender);
      const { data } = await axios.post(
        `${AUTHORIZED_URL_PREFIX}${EDIT_PROFILE_URL}`,
        formData,
        AxiosConfig("multipart/form-data")
      );
      if (!data.success) {
        setUserDescription(user.description);
        setFile();
        notify(pleaseRefreshError);
      } else {
        notify({
          title: `${t("You have successfully updated your profile!")}`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        });
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      notify(pleaseRefreshError);
    }
  };

  return ( contextUser ?
    <div className="lykiocss-profile-text-color card mb-4">
      <div className="card-body text-center" style={{ border: "2px solid var(--primary-color)" }}>
        <div className="lykiocss-center">
          <img
            src={user.profilepicture}
            alt="avatar"
            className="rounded-circle img-fluid"
            style={{ width: "150px", height:"150px" }}
          />
        </div>
        <h5 className="my-3">{`${user.fullName}`}</h5>
        <p className="my-3">{`${user.email}`}</p>
        <p className="text-muted mb-1">{subtext} {group && <ActiveGroup name={group.name} reference={group.reference} />} </p>
        <div className="d-flex justify-content-center mb-2">
          <div>
            {!isMyProfile &&
              userId !== "me" &&
              contextUser.id !== userId &&
              showFollow && (
                <LykioButton
                  onClick={() => {
                    followUserHandler();
                  }}
                >
                  {t("Follow")}
                </LykioButton>
              )}
            {!isMyProfile &&
              userId !== "me" &&
              contextUser.id !== userId &&
              !showFollow && (
                <LykioButton
                  onClick={() => {
                    unfollowUserHandler();
                  }}
                >
                  {t("Unfollow")}
                </LykioButton>
              )}
            {(isMyProfile || userId === "me") && (
              <LykioButton
                onClick={() => {
                  setShowEditModal(true);
                }}
              >
                {t("Edit")}
              </LykioButton>
            )}
          </div>
        </div>
        <Modal
          centered
          show={showEditModal}
          onHide={() => {
            setShowEditModal(false);
          }}
        >
          <Modal.Body>
            <Container>
              <Form>
                <h3 className="lykiocss-center">{t("Update your profile")}</h3>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 lykiocss-center">
                  <h6 className="mb-2 text-primary">{t("Personal Details")}</h6>
                </div>
                <Form.Group className="mb-3" controlId="userDescription">
                  <FloatingLabel controlId="floatingay" label={t(`About you`)}>
                  <div style={{fontSize:'12px'}}>{t("(up to 30 characters)")}</div>
                  <Form.Control
                    type="text"
                    maxLength="30"
                    placeholder={user.description}
                    onChange={(e) => {
                      setUserDescription(e.target.value);
                    }}
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>{t(`Your new profile picture`)}</Form.Label>
                  <Form.Control
                    filename={file}
                    accept="image/*"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </Form.Group>

                <Form.Group controlId="firstName" className="mb-3">
                  <FloatingLabel controlId="floatingfn" label={user.firstname ? user.firstname : t('Your first name')}>
                  <Form.Control
                    type="text"
                    maxLength="50"
                    placeholder={user.firstname}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="lastname" className="mb-3">
                  <FloatingLabel controlId="floatingln" label={user.lastname ? user.lastname : t('Your last name')}>
                  <Form.Control
                    type="text"
                    maxLength="50"
                    placeholder={user.lastname}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                  </FloatingLabel>
                </Form.Group>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 lykiocss-center">
                  <h6 className="mb-2 text-primary">
                    {t("Contact Information *optional")}
                  </h6>
                </div>
                <Form.Group controlId="address" className="mb-3">
                  <FloatingLabel controlId="floatingya" label={user.address ? user.address : t('Your address')}>
                  <Form.Control
                    type="text"
                    maxLength="50"
                    placeholder={user.address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="city" className="mb-3">
                  <FloatingLabel controlId="floatingcity" label={user.city ? user.city : t('Your city')}>
                  <Form.Control
                    type="text"
                    maxLength="50"
                    placeholder={user.city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="state" className="mb-3">
                  <FloatingLabel controlId="floatingstate" label={user.state ? user.state : t('Your state')}>
                  <Form.Control
                    type="text"
                    maxLength="50"
                    placeholder={user.state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="postcode" className="mb-3">
                  <FloatingLabel controlId="floatingpc" label={user.postcode ? user.postcode : t('Post Code')}>
                  <Form.Control
                    type="text"
                    maxLength="30"
                    placeholder={user.postcode}
                    onChange={(e) => {
                      setPostcode(e.target.value);
                    }}
                  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group controlId="country" className="mb-3">
                  <FloatingLabel controlId="floatingcountry" label={user.country ? user.country : t('Country')}>
                  <Form.Control
                    type="text"
                    maxLength="50"
                    placeholder={user.country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group controlId="phone" className="mb-3">
                  <FloatingLabel controlId="floatingp" label={user.phone ? user.phone : t('Phone')}>
                  <Form.Control
                    type="tel"
                    maxLength="30"
                    placeholder={user.phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="gender" className="mb-3">
                  <FloatingLabel controlId="floatinggender" label={user.gender ? user.gender : t('Your gender')}>
                  <Form.Control
                    type="text"
                    maxLength="50"
                    placeholder={user.gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  />
                  </FloatingLabel>
                </Form.Group>

                <LykioButton
                  variant="contained"
                  type="submit"
                  className="float-end"
                  onClick={() => {
                    updateUserProfileHandler();
                  }}
                >
                  {t("Update")}
                </LykioButton>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    </div> : <div className="lykiocss-profile-text-color card mb-4"></div>
  );
};

export default ProfileImageArea;
