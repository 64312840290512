import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import microsoft_logo from "../../multimedia/ms_logo.png";
import "./LoginScreen.css";
import {
  HOME_URL,
  HOME_WITH_WELCOME_URL,
  INITIAL_SETUP_URL,
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  LOGIN_URL,
  LOGIN_WITH_GOOGLE,
  LOGIN_WITH_AZURE_AD,
  NOTIFICATION_SEVERITY_WARNING,
  GOOGLE_CLIENT_ID,
  TEXTS_LOGIN,
  TEXTS_PASS,
  TEXTS_EMAIL,
  TEXTS_FORGOTPASS,
  TEXTS_NOACCOUNT,
  TEXTS_REGISTER,
  TEXTS_MISSED_ACTIVATION,
  TEXTS_RESEND_ACTIVATION,
  TEXTS_ERROR_ON_LOGIN,
  TEXTS_ERROR_ON_GOOGLE_LOGIN,
  FRONTEND_REGISTER_USER_WITH_ACCESS_CODE_URL,
  FRONTEND_REGISTER_USER_WITH_MAGIC_CODE_URL,
  FRONTEND_REGISTER_URL,
  FRONTEND_SINGLE_TENANCY_REGISTER_URL,
  AxiosConfig,
  AxiosConfigNoAuth,
  WELCOME_URL,
  FRONTEND_DOWNLOAD_GAME_URL
} from "../../Utils/Constants";
import notify from "../../Utils/Notificator";
import t from "../../Utils/Translation";
import * as Msal from "msal";
// import GoogleLogin from 'react-google-login';

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
	const [showRegisterOrg, setShowRegisterOrg] = useState(true);
  const [showRegisterToLykio, setShowRegisterToLykio] = useState(true);
  const [showRegisterWithMagicCode, setShowRegisterWithMagicCode] = useState(true);
  const [showRegisterWithAccessCode, setShowRegisterWithAccessCode] = useState(true);
  const [showForgotPassMessage, setShowForgotPassMessage] = useState(true);
  const [forgotPassMessage, setForgotPassMessage] = useState('');
  const [authScreensLogo, setAuthScreensLogo] = useState();
  const [hideLoginFields, setHideLoginFields] = useState(false);
  const [showDownloadsPage, setShowDownloadsPage] = useState(false);
  const [enableSchools, setEnableSchools] = useState(true);

  const [enableAzureADlogin, setEnableAzureADLogin] = useState(false);
  const [azureADClientID, setAzureADClientID] = useState('fa4cd176-c225-4766-a67a-b8460e63b709');
  const [azureADAuthority, setAzureADAuthority] = useState('https://login.microsoftonline.com/common');
  const [azureADRedirectURI, setAzureADRedirectURI] = useState('http://localhost:3000/login');
  const [azureADClientSecret, setAzureADClientSecret] = useState("secret");

  const navigate = useNavigate();

  const authenticateMSAL = async()=>{
    console.log('running Authentication...');
    try{
      const config = {
        auth : {
            clientId : azureADClientID,
            authority: azureADAuthority,
            redirectUri: azureADRedirectURI,
            clientSecret: azureADClientSecret
        }
      }

      var client = new Msal.UserAgentApplication(config);
      var request = {
          scopes: [ 'user.read' ]
      }
      if (!localStorage.getItem('authToken')) {
        let loginResponse = await client.loginPopup(request);
        console.log(loginResponse);
        let name = loginResponse.account.name;
        let email = loginResponse.account.userName;
        let accountIdentifier = loginResponse.account.accountIdentifier
        if(email && name){
          const { data } = await axios.post(
            LOGIN_WITH_AZURE_AD,
            {
              name: name,
              email: email,
              accountIdentifier : accountIdentifier
            },
            AxiosConfigNoAuth()
          );
          if(data && data.success){
            console.log('received token');
            localStorage.setItem("authToken", data.authToken);
            !localStorage.getItem("i18n") && localStorage.setItem("i18n", "en");
            !localStorage.getItem("content-i18n") &&
              localStorage.setItem("content-i18n", "en");
            if(data.mustSetUpTenant && !enableSchools){
              navigate(INITIAL_SETUP_URL)
            }else if(!data.mustSetUpTenant && data.logins<2 && !enableSchools){
              navigate(WELCOME_URL);
            }else{
              navigate(HOME_URL)
            }
          }else{
            notify({
              title: 'Could not login with Microsoft credentials. Contact your administrator.',
              severity: NOTIFICATION_SEVERITY_WARNING,
              creationDate: "",
            });
          }
        }else{
          notify({
            title: 'Could not extract details from your Microsoft account. Contact your administrator.',
            severity: NOTIFICATION_SEVERITY_WARNING,
            creationDate: "",
          });
        }
      }else{
        navigate({ HOME_URL });
      }
    }catch(error){
      console.error(error);
      notify({
        title: 'Could not login with Microsoft credentials. Contact your administrator.',
        severity: NOTIFICATION_SEVERITY_WARNING,
        creationDate: "",
      });
    }
}

  const onGoogleLoginSuccess = async (res) => {
    // The res.profileObj contains email, name (full name), given name, family name, googleId, imageUrl
    console.log("Login Success:", res.profileObj);

    try {
      const { data } = await axios.post(
        `${LOGIN_WITH_GOOGLE}`,
        {
          firstname: res.profileObj.givenName,
          lastname: res.profileObj.familyName,
          email: res.profileObj.email,
          profilepicture: res.profileObj.imageUrl,
          googleId: res.profileObj.googleId,
        },
        AxiosConfigNoAuth()
      );
      console.log(
        `Login: made the call to ${LOGIN_WITH_GOOGLE} and got token:${data.authToken}`
      );
      localStorage.setItem("authToken", data.authToken);
      localStorage.setItem("googleSSO", data.googleSSO);
      navigate(HOME_URL);
    } catch (error) {
      console.error(error);
      notify({
        title: TEXTS_ERROR_ON_GOOGLE_LOGIN,
        severity: NOTIFICATION_SEVERITY_WARNING,
        creationDate: "",
      });
    }
  };

  const onGoogleLoginFailure = () => {
    notify({
      title: TEXTS_ERROR_ON_GOOGLE_LOGIN,
      severity: NOTIFICATION_SEVERITY_WARNING,
      creationDate: "",
    });
  };

  useEffect(() => {
    async function fetchAdminSettings() {
      const { data } = await axios.get(
        `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
        AxiosConfigNoAuth()
      );
      data.uberSettings &&
        setShowRegisterOrg(data.uberSettings.showRegisterOrg);
      data.uberSettings &&
        setShowRegisterToLykio(data.uberSettings.showRegisterToLykio);
      data.uberSettings &&
        setShowRegisterWithMagicCode(data.uberSettings.showRegisterWithMagicCode);
      data.uberSettings &&
        setShowRegisterWithAccessCode(
          data.uberSettings.showRegisterWithAccessCode);
      data.uberSettings &&
        setShowForgotPassMessage(
          data.uberSettings.showForgotPassMessage);
      data.uberSettings && setForgotPassMessage(data.uberSettings.forgotPassMessage);
      data.uberSettings && setAuthScreensLogo(data.uberSettings.authScreensLogo);
      data.uberSettings &&
      setHideLoginFields(
        data.uberSettings.hideLoginFields);

      data.uberSettings &&
        setShowDownloadsPage(
          data.uberSettings.showDownloadsPage);

      data.uberSettings &&
      setEnableSchools(
        data.uberSettings.enableSchools);

        data.uberSettings &&
        setEnableAzureADLogin(
          data.uberSettings.enableAzureADlogin
        );
  
        data.uberSettings &&
        setAzureADClientID(
          data.uberSettings.azureADClientID
        );
  
        data.uberSettings &&
        setAzureADAuthority(
          data.uberSettings.azureADAuthority
        );
  
        data.uberSettings &&
        setAzureADRedirectURI(
          data.uberSettings.azureADRedirectURI
        );

        data.uberSettings &&
        setAzureADClientSecret(
          data.uberSettings.azureADClientSecret
        );

      const storageAuthTokenValue = localStorage.getItem("authToken");
      if (storageAuthTokenValue) {
        navigate(HOME_URL);
      }
    }
    fetchAdminSettings();
  }, []);

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        LOGIN_URL,
        { email, password },
        AxiosConfigNoAuth()
      );
      localStorage.setItem("authToken", data.authToken);
      !localStorage.getItem("i18n") && localStorage.setItem("i18n", "en");
      !localStorage.getItem("content-i18n") &&
        localStorage.setItem("content-i18n", "en");
      if(data.mustSetUpTenant && !enableSchools){
        navigate(INITIAL_SETUP_URL)
      }else if(!data.mustSetUpTenant && data.logins<3 && !enableSchools){
        navigate(WELCOME_URL);
      }else{
        navigate(HOME_URL)
      }
    } catch (error) {
      console.error(error);
      notify({
        title: TEXTS_ERROR_ON_LOGIN,
        severity: NOTIFICATION_SEVERITY_WARNING,
        creationDate: "",
      });
    }
  };

  return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
    <div className="lykiocss-screen-center">
      <Form
        onSubmit={loginHandler}
        className="LykioForm lykiocss-white-background lykiocss-form-border lykiocss-form-size"
      >
        <Link to={HOME_URL}>
          <div className="lykiocss-center">
            <img
              className="lykiocss-login-logo"
              src={authScreensLogo ? authScreensLogo : lykio_logo}
              alt="login_logo"
            />
          </div>
        </Link>

        <Form.Group className={`${hideLoginFields && 'lykiocss-hide'} lykiocss-margin-bottom-half-em`} controlId="email">
          <FloatingLabel controlId="floatingemail" label="Email or Username">
            <Form.Control
              required
              type="text"
              placeholder="email or username"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group className={`${hideLoginFields && 'lykiocss-hide'} lykiocss-margin-bottom-half-em`} controlId="password">
          <FloatingLabel controlId="floatingpass" label={TEXTS_PASS}>
            <Form.Control
              required
              type="password"
              placeholder={t("Enter password (6 characters or more)")}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group className={`${hideLoginFields && 'lykiocss-hide'}`}>
          <Button
            variant="primary"
            type="submit"
            className="float-end"
            size="sm"
          >
            {TEXTS_LOGIN}
          </Button>
        </Form.Group>

        <div className="lykiocss-login-screen-extra-options">
          <Container>

          {enableAzureADlogin && (<div className="lykiocss-center">
          <div className="lykiocss-login-screen-subtext">
                <Button variant="outline-secondary" className="float-end" size="sm" onClick={authenticateMSAL}>
                {t("Login with ")} <Image style={{width:'7em'}} src={microsoft_logo}/>
                </Button>
          </div>
          </div>)}

          {showRegisterOrg && (<div className="lykiocss-center">
          <div className="lykiocss-login-screen-subtext">
            <Link to="/register">
              <Button variant="outline-secondary" className="float-end" size="sm">
              {t("Register Your Organization")}
              </Button>
            </Link>
          </div>
          </div>)}
          {showRegisterToLykio && (<div className="lykiocss-center">
          <div className="lykiocss-login-screen-subtext">
            <Link to={FRONTEND_SINGLE_TENANCY_REGISTER_URL}>
                <Button variant="outline-secondary" className="float-end" size="sm">
                {t("Register with email")}
                </Button>
            </Link>
          </div>
          </div>)}
          {showRegisterWithMagicCode && (<div className="lykiocss-center">
          <div className="lykiocss-login-screen-subtext">
            <Link to={FRONTEND_REGISTER_USER_WITH_MAGIC_CODE_URL}>
                <Button variant="outline-secondary" className="float-end" size="sm">
                {t("Register with magic code")}
                </Button>
            </Link>
          </div>
          </div>)}
          {showDownloadsPage && (<div className="lykiocss-center">
          <div className="lykiocss-login-screen-subtext">
            <Link to={FRONTEND_DOWNLOAD_GAME_URL}>
                <Button variant="outline-secondary" className="float-end" size="sm">
                {t("Download the Game")}
                </Button>
            </Link>
          </div>
          </div>)}
          
          <div className="lykiocss-center">
          <div className="lykiocss-login-screen-subtext">
          <Link to="/forgotpassword">
            <Button variant="outline-secondary" className="float-end" size="sm">
              {TEXTS_FORGOTPASS}
            </Button>
          </Link>
          </div>
          </div>

          {showForgotPassMessage && (<div className="lykiocss-center">
            <div className="lykiocss-center">
            <p className="lykiocss-center lykiocss-login-light">{forgotPassMessage}</p>
            </div>
          </div>)}

          {showRegisterWithAccessCode && (<div className="lykiocss-center">
          <div className={`lykiocss-login-screen-subtext ${enableSchools && 'tt-access-code-button'}`}>
            {enableSchools ? 
            <a href={FRONTEND_REGISTER_USER_WITH_ACCESS_CODE_URL} 
            style={{color: 'purple', fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize', textDecoration: 'none'}}>
              Use your access code to register
            </a>
            :
              <Link to={FRONTEND_REGISTER_USER_WITH_ACCESS_CODE_URL}>
                 <Button variant="outline-secondary" className="float-end" size="sm">
                 {t("Use your access code to register")}
                 </Button>
              </Link>
            }

          </div>
          </div>)}

          <div className="lykiocss-center">
          <div className="lykiocss-login-screen-subtext">
            <Link to="/resendactivation">
            <Button variant="outline-secondary" className="float-end" size="sm">
              {TEXTS_MISSED_ACTIVATION}
            </Button>
            </Link>
          </div>
          </div>

          </Container>
        </div>
        {/* <GoogleLogin
			clientId={GOOGLE_CLIENT_ID}
			buttonText="Sign In"
			onSuccess={onGoogleLoginSuccess}
			onFailure={onGoogleLoginFailure}
			cookiePolicy={'single_host_origin'}
			isSignedIn={true}
		/> */}
      </Form>
    </div>
    </div>
  );
};

export default LoginScreen;
