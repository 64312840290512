import { useState, useEffect, useContext } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  AUTHORIZED_URL_PREFIX,
  PROFILE_URL_WITHOUT_PARAM,
  AxiosConfig,
} from "../../Utils/Constants";
import "./profile.css";
import Loading from "../common/Loading";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import TimelineBox from "./TimelineBox";
import StoriesUploadBox from "./StoriesUploadBox";
import ProfileAwards from "./ProfileAwards";
import ProfileImageArea from "./ProfileImageArea";
import ProfileStats from "./ProfileStats";
import FavouriteUnits from "./FavouriteUnits";
import ProfileAccessCodes from "./ProfileAccessCodes";
import SupportEmail from "../common/SupportEmail";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const context = useContext(LykioContext);
  const contextUser = context && context.userDetails;
  const tenantProperties = context.tenantProperties;
	const [enableSchools, setEnableSchools] = useState(true);
  // const enableSchools = tenantProperties && tenantProperties.enableSchools;
  const { userId } = useParams();
  const [user, setUser] = useState("");
  const [stories, setStories] = useState([]);
  const [favourites, setFavourites] = useState([]);
  const [userHistoryItems, setUserHistoryItems] = useState([]);
  const [activeAccessCodes, setActiveAccessCodes] = useState([]);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const { data } = await axios.post(
          `${AUTHORIZED_URL_PREFIX}${PROFILE_URL_WITHOUT_PARAM}`,
          { userId },
          AxiosConfig()
        );
        setUser(data.user);
        setStories(data.stories);
        setFavourites(data.favourites);
        setUserHistoryItems(data.userHistoryItems);
        setActiveAccessCodes(data.activeAccessCodes);
        setEnableSchools(data.enableSchools);
        
        setIsLoading(false);
        if (!data.success) {
          notify(pleaseRefreshError);
        }
      } catch (error) {
        console.log(error);
        notify(pleaseRefreshError);
      }
    };
    fetchProfileData();
  }, [userId]);

  return (
    <div className="lykioccs-main-content">
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <section>
            <div className="container py-5">
              <div className="row">
                <div className="col-lg-4">
                  <ProfileImageArea
                    userId={userId}
                    user={user}
                    contextUser={contextUser}
                  />

                  {!enableSchools &&
                  <div className="card mb-4 mb-lg-0" style={{ border: "2px solid var(--primary-color)" }}>
                    <ProfileStats user={user} />
                  </div>
                  } 
                  
                </div>

                {enableSchools ? <div className="col-lg-8">
                  <ProfileAccessCodes user={user} activeAccessCodes={activeAccessCodes}/>
                </div> : <div className="col-lg-8">
                  <ProfileAwards user={user} awards={contextUser && contextUser.awards}/>
                  <div className="row">
                    <TimelineBox userHistoryItems={userHistoryItems} />
                    <StoriesUploadBox stories={stories} />
                    <FavouriteUnits stories={favourites} />
                  </div>
                </div>}

              </div>
            </div>
          </section>
        </React.Fragment>
      )}
    {enableSchools && <SupportEmail/>}
    </div>
  );
};

export default Profile;
