import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {AUTHORIZED_URL_PREFIX, UNIT_BY_ID_URL, AxiosConfig, GET_SCORM_LAUNCH_URL} from '../../Utils/Constants';
import './unitIFrame.css';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import registerUnitViewed from '../../Utils/ApiCalls';

const UnitIFrame = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ s3Url, setS3Url ] = useState('');
	const [ h5p, setH5P] = useState();
	const { unitId } = useParams();
	const userDetails = localStorage.getItem('user');
	const [isScormType, setIsScormType] = useState(false)
	const [scormURL, setScormURL] = useState('')

	useEffect(() => {
		const fetchUnitData = async () => {
			try {
				registerUnitViewed({ unitId: unitId, progress: null, score: null })
				const { data } = await axios.post(
					`${AUTHORIZED_URL_PREFIX}${UNIT_BY_ID_URL}`,
					{ unitId },
					AxiosConfig()
				);
				if (data.success) {
					setS3Url(data.unit.s3Url);
					console.log('setting h5p to '+data.unit.h5p)
					setH5P(data.unit.h5p);

					if(data.unit.type === 'System-Scorm') {
						const response = await axios.post(
							`${AUTHORIZED_URL_PREFIX}${GET_SCORM_LAUNCH_URL}`,
							{ unitId },
							AxiosConfig()
						);
						if(response.data.success) {
							setScormURL(response.data.launchLink);
							setIsLoading(false);
						}
						setIsScormType(true)
					} else {
						// console.log('location',window.location.href.split('/unit/')[0]);
						// console.log('posting auth details to iframe');
						setIsLoading(false);
						let iframes = document.getElementsByTagName('iframe');
						if(iframes.length>0){
							iframes[0].onload = function() {
								var win = iframes[0].contentWindow;
								var obj = {
									parentUrl: window.location.href.split('/unit/').length>0 && window.location.href.split('/unit/')[0],
									unitId: unitId,
									categoryreference: data.unit.categoryreference,
									user: JSON.parse(userDetails),
									authToken: localStorage.getItem("authToken")
								};
								win.postMessage(JSON.stringify({key: 'storage', data: obj}), "*");
								console.log('auth details posted')
							}
						}
					}

				}else{
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.error(error);
				notify(pleaseRefreshError);
			}
		};
		fetchUnitData();
	}, []);

	return <div>
		{isLoading ? <Loading /> : <div style={{height:'100%'}}>
			<div className={h5p ? "h5p-box" : "lykiocss-unitiframe-iframe"}>
				{h5p ? (
					<div className="h5p-content lykiocss-center" dangerouslySetInnerHTML={{__html: s3Url}}></div>
				) : (
					isScormType ? (
						<iframe src={scormURL}/>
					) : (
						<iframe src={s3Url} />
					)
				)}


			</div>
		</div>}
	</div>
};

export default UnitIFrame;
