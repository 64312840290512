import { useState, useRef, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import t from "../../Utils/Translation";
import './comments.css';
import {
	AUTHORIZED_URL_PREFIX,
	UNIT_BY_ID_URL,
	ADD_COMMENT_URL,
	NOTIFICATION_SEVERITY_WARNING,
	AxiosConfig
} from '../../Utils/Constants';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import StarRating from './StarRating';
import timeSince from '../../Utils/TimeSince';
import InputEmoji from 'react-input-emoji';
import Loading from './Loading';
import ActiveProfileImage from './ActiveProfileImage';
import Favourite from './Favourite';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';

const Comments = (props) => {
	const context = useContext(LykioContext);
	const user = context.userDetails;
	const [ unit, setUnit ] = useState([]);
	const [ comments, setComments ] = useState([]);
	const [ comment, setComment ] = useState({});
	const [ commenterName, setCommenterName ] = useState('');
	const commentsAreaRef = useRef();
	const postDivButtonRef = useRef();
	const inputEmojiRef = useRef();
	const { unitId } = useParams();
	const [ text, setText ] = useState('');
	const [ isLoading, setIsLoading ] = useState(true);
	let storageUser;
	const API_KEY = "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"; 

	useEffect(() => {
		if (!user) {
			storageUser = JSON.parse(localStorage.getItem('user'));
		} else {
			localStorage.setItem('user', JSON.stringify(user));
			storageUser = user;
		}
		const fetchCommentsData = async () => {
			try {
				let storyId;
				unitId ? (storyId = unitId) : (storyId = props.story._id); // this is needed for the commments comp to work for both units and stories
				const { data } = await axios.post(
					`${AUTHORIZED_URL_PREFIX}${UNIT_BY_ID_URL}`,
					{ unitId: storyId },
					AxiosConfig()
				);
				if (data.success) {
					setUnit(data.unit);
					setComments(data.unit.comments);
					data.unit.uploader.firstname
							? setCommenterName(data.unit.uploader.firstname + ' ' + data.unit.uploader.lastname)
							: setCommenterName(data.unit.uploader.email);
					setIsLoading(false);
					commentsAreaRef.current.scrollIntoView(false);
				} else {
					notify(pleaseRefreshError);
				}
			} catch (error) {
				console.log(error);
				notify({
					title: `${t('Could not retrieve comments. Please refresh.')}`,
					severity: NOTIFICATION_SEVERITY_WARNING,
					creationDate: ''
				});
			}
		};
		if (props.unit) {
			setUnit(props.story);
			setComments(props.story.comments);
			user.firstname ? setCommenterName(user.firstname + ' ' + user.lastname) : setCommenterName(user.email);
		} else {
			fetchCommentsData();
		}
	}, []);

	const scrollToBottom = () => {
		if (commentsAreaRef.current) {
			commentsAreaRef.current.scrollIntoView(false);
		}
	};

	useEffect(scrollToBottom, [ comments ]);

	useEffect(
		() => {
			if (comment && comment.comment && comment.comment.length > 0) {
				setComments([ ...comments, comment ]);
				if (props.updateComments) {
					props.updateComments(props.totalComments + 1);
				}
				handleCommentSubmit();
			}
		},
		[ comment ]
	);

	useEffect(
		() => {
			if (postDivButtonRef.current) {
				if (text.length > 0) {
					postDivButtonRef.current.style.opacity = '1';
				} else {
					postDivButtonRef.current.style.opacity = '0.3';
				}
			}
		},
		[ text ]
	);

	const analyzeSentiment = async (comment) => {
		const APIBody = {
			"model": "text-davinci-003",
			"prompt": "What is the sentiment of this comment? Positive or Negative. Please answer with just one word. " + comment,
			"temperature": 0,
			"max_tokens": 60,
			"top_p": 1.0,
			"frequency_penalty": 0.0,
			"presence_penalty": 0.0
		}
		try {
			const response = await axios.post('https://api.openai.com/v1/completions', APIBody, {
			  headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + API_KEY
			  }
			});
		  
			const data = response.data.choices[0].text.trim();
			return data.toLowerCase().includes('positive');
		  } catch (error) {
			console.log(error);
			notify({
				title: `${t('The sentiment of your comment was not detected. Please refresh and try again.')}`,
				severity: NOTIFICATION_SEVERITY_WARNING,
				creationDate: ''
			});
		  }
	  };

	const handleCommentSubmit = async () => {
		setText('');
		try {
			const positive = await analyzeSentiment(comment.comment);
			let storyId;
			unit._id ? (storyId = unit._id) : (storyId = props.story._id); // this is needed for the commments comp to work for both units and stories
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${ADD_COMMENT_URL}`,
				{ unitId: storyId, comment: comment.comment, creationdate: new Date(), positive },
				AxiosConfig()
			);
			if (!data.success) {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.log(error);
			notify({
				title: `${t('Your comment was not added. Please refresh and try again.')}`,
				severity: NOTIFICATION_SEVERITY_WARNING,
				creationDate: ''
			});
		}
	};

	const handleEnterButton = () => {
		if (text.length > 0) {
			setComment({
				...comment,
				comment: text,
				commentTimeStamp: new Date().toLocaleString(),
				commenterName: `${user.firstname} ${user.lastname}`,
				commenterProfPic: user.profilepicture
			});
		}
	};

	return isLoading ? (
		<Loading />
	) : (
		<div className="lykiocss-comments-wrap">
			{props.story &&
			<div className="lykiocss-comments-heading">
				{props.story ? (
					<div className="lykiocss-comments-uploader-info">
						<div>
						<ActiveProfileImage user={props.story.uploader} showName={true}/>
						</div>
						{/* <div className="lykiocss-comments-uploader-container">
						<div className="lykiocss-comments-uploader-name">{commenterName}</div>
						</div> */}
					</div>
				) : (
					<div className="lykiocss-comments-uploader-info">
						<div className="lykiocss-comments-div">
						<ActiveProfileImage user={unit.uploader} />
						</div>
						<div className="lykiocss-comments-uploader-container">
							<div className="lykiocss-comments-uploader-name">{unit.uploader.fullName}</div>
						</div>
					</div>
				)}
			</div>
			}
			<div className="lykiocss-comments-area">
				<div className="lykiocss-comments-middle">
					<ul ref={commentsAreaRef} className="lykiocss-comments-container">
						{comments.length === 0 && <span>{t('No comments yet. Be the first to comment!')}</span>}
						{comments.map((comment, index) => (
							<ul className="lykiocss-comments-singlecomment" key={index}>
								<div className="lykiocss-comments-div">
								{comment.commenterId && <ActiveProfileImage user={comment.commenterId} />}
								</div>
								<div className="lykiocss-comments-details">
									<span className="lykiocss-comments-commentername">{comment.commenterName}</span>
									<span className="lykiocss-comments-commenttext">{comment.comment}</span>
									<div className="lykiocss-comments-commentdate">
										{timeSince(comment.commentTimeStamp)}
									</div>
								</div>
							</ul>
						))}
					</ul>
				</div>
			</div>
			<div>
				<div className="lykiocss-comments-rating">
					<div>
						<StarRating rating={props.story} />
					</div>
					<div className="lykiocss-comments-bookmarkbutton">
						<Favourite unitId={unitId ?  unitId : props.story._id}/>
					</div>
				</div>
				<div className="lykiocss-comments-commentbox">
					<div className="lykiocss-comments-input-emoji">
						<InputEmoji
							ref={inputEmojiRef}
							value={text}
							onChange={setText}
							cleanOnEnter
							onEnter={handleEnterButton}
							placeholder={text.length === 0 && t('Add a comment...')}
						/>
						<div ref={postDivButtonRef} className="lykiocss-comments-postbuttondiv">
							<button type="button" className="lykiocss-comments-postbutton" onClick={handleEnterButton}>
								{t("Post")}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Comments;
