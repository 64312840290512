import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import {
  HOME_URL,
  REGISTER_USER_WITH_ACCESS_CODE_URL,
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  FRONTEND_LOGIN_URL,
  NOTIFICATION_SEVERITY_SUCCESS,
  NOTIFICATION_SEVERITY_WARNING,
  AxiosConfigNoAuth,
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import "./RegisterScreen.css";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import GDPR from "./GDPR";

const RegisterWithMagicCode = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [authScreensLogo, setAuthScreensLogo] = useState();
  const [gender, setGender] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [postcode, setPostcode] = useState("");

  const AVAILABLE_ROLES = ["Student", "Teacher"];
  const [isStudentSignup, setIsStudentSignup] = useState(true);

  const [supportEmail, setSupportEmail] = useState("")

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  useEffect(async () => {
    const { data } = await axios.get(
      `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
      AxiosConfigNoAuth()
    );
    data.uberSettings && setAuthScreensLogo(data.uberSettings.authScreensLogo);
    data.uberSettings && setSupportEmail(data.uberSettings.supportEmail);

    const storageAuthTokenValue = localStorage.getItem("authToken");
    if (storageAuthTokenValue) {
      navigate(FRONTEND_LOGIN_URL);
    }
  }, []);

  const registerHandler = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      notify({
        title: `${t("Password must at least 6 characters long!")}`,
        severity: NOTIFICATION_SEVERITY_WARNING,
        creationDate: "",
      });
    } else {
      if (password !== confirmPassword) {
        setPassword("");
        setConfirmPassword("");
        notify({
          title: `${t("Passwords do not match")}`,
          severity: NOTIFICATION_SEVERITY_WARNING,
          creationDate: "",
        });
      } else {
        try {
          const { data } = await axios.post(
            REGISTER_USER_WITH_ACCESS_CODE_URL,
            { firstname, lastname, email, username, password, accessCode, gender, schoolName, address, city, state, country, phone, postcode, isStudentSignup },
            AxiosConfigNoAuth()
          );
          if(data.success) {
            notify({
              title: `${t(
                "You have successfully registered. An activation email was sent to your email."
              )}`,
              severity: NOTIFICATION_SEVERITY_SUCCESS,
              creationDate: "",
            });
          }
          navigate(FRONTEND_LOGIN_URL);
        } catch (error) {
          notify({
            title: `${t(
              `Oops! Something went wrong. 
              Make sure you have not used this email address in the past.
              Double check your access code and try adding some numbers to your username to make it unique.`
            )}`,
            severity: NOTIFICATION_SEVERITY_WARNING,
            creationDate: "",
          });
        }
      }
    }
  };

  return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
      <div className="lykiocss-screen-center">
        <Form
          onSubmit={registerHandler}
          className="LykioForm lykiocss-white-background lykiocss-form-border lykiocss-form-size"
        >
          <Link to={HOME_URL}>
            <div className="lykiocss-center">
              <img
                className="lykiocss-small-login-logo"
                src={authScreensLogo ? authScreensLogo : lykio_logo}
                alt=""
              />
            </div>
          </Link>
          <h3 className="register-screen__title">
            {t("Sign up with Access Code")}
          </h3>
          <FloatingLabel controlId="floatingrole" label={t(`I am registering as a`)}>
            <Form.Select onChange={(e)=> setIsStudentSignup(e.target.value==='Student')}>
            {AVAILABLE_ROLES.map((role, i) => (
              <option
              selected={role === 'Student'}
              >
              {role}
              </option>
            ))}
            </Form.Select>
          </FloatingLabel>
          <br/>


        {!isStudentSignup && <React.Fragment>
          <Form.Group className="mb-3" controlId="firstname">
          <FloatingLabel controlId="floatingname" label={t(`First name`)}>
            <Form.Control
              required
              type="text"
              placeholder={t("First name")}
              value={firstname}
              onChange={(e) => {
                setFirstname(e.target.value);
              }}
            />
           </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="lastname">
          <FloatingLabel controlId="floatinglastname" label={t(`Last name`)}>
            <Form.Control
              required
              type="text"
              placeholder={t("Last name")}
              value={lastname}
              onChange={(e) => {
                setLastname(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>
          </React.Fragment>}

          <Form.Group className="mb-3" controlId="email">
          <FloatingLabel controlId="floatingemail" label={t(`Your email address`)}>
            <Form.Control
              required
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>

          {isStudentSignup && 
          <Form.Group className="mb-3" controlId="username">
          <FloatingLabel controlId="floatingun" label={t(`Username (make it unique e.g. jack6731)`)}>
            <Form.Control
              required
              type="text"
              placeholder="Username (make it unique e.g. jack6731)"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>
          }

          <Form.Group className="mb-3" controlId="accessCode">
          <FloatingLabel controlId="floatingaccess" label={isStudentSignup ? t("Your access code (found behind your book cover)") : t("Your access code or school id")}>
            <Form.Control
              required
              type="text"
              placeholder={isStudentSignup ? t("Your access code") : t("Your access code or school id")}
              value={accessCode}
              onChange={(e) => {
                setAccessCode(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="gender">
          <FloatingLabel controlId="floatinggender" label={t(`Gender (optional)`)}>
            <Form.Control
              type="text"
              placeholder={t("Gender (optional)")}
              value={gender}
              onChange={(e) => {
                setGender(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>

        {!isStudentSignup && <React.Fragment>
          <Form.Group className="mb-3" controlId="schoolName">
          <FloatingLabel controlId="floatingschool" label={t(`School Name (optional)`)}>
            <Form.Control
              type="text"
              placeholder={t("School Name (optional)")}
              value={schoolName}
              onChange={(e) => {
                setSchoolName(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="address">
          <FloatingLabel controlId="floatingaddress" label={t(`Address (optional)`)}>
            <Form.Control
              type="text"
              placeholder={t("Address (optional)")}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>
          </React.Fragment>}

          <Form.Group className="mb-3" controlId="city">
          <FloatingLabel controlId="floatingcity" label={t(`City`)}>
            <Form.Control
              type="text"
              required
              placeholder={t("City")}
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="state">
          <FloatingLabel controlId="floatingstate" label={t(`State`)}>
            <Form.Control
              type="text"
              required
              placeholder={t("State")}
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="country">
          <FloatingLabel controlId="floatingcountry" label={t(`Country`)}>
            <Form.Control
              type="text"
              required
              placeholder={t("Country")}
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>


        {!isStudentSignup && <React.Fragment>
          <Form.Group className="mb-3" controlId="postcode">
          <FloatingLabel controlId="floatingpost" label={t(`Postal Code (optional)`)}>
            <Form.Control
              type="text"
              placeholder={t("Postal Code (optional)")}
              value={postcode}
              onChange={(e) => {
                setPostcode(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>

          </React.Fragment>}

          <Form.Group className="mb-3" controlId="phone">
          <FloatingLabel controlId="floatingphone" label={t(`Phone (optional)`)}>
            <Form.Control
              type="text"
              placeholder={t("Phone (optional)")}
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
          <FloatingLabel controlId="floatingpass" label={t(`Enter password (6 characters or more)`)}>
            <Form.Control
              required
              type="password"
              placeholder={t("Enter password (6 characters or more)")}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmpassword">
          <FloatingLabel controlId="floatingpassagain" label={t(`Confirm your password`)}>
            <Form.Control
              required
              type="password"
              placeholder={t("Confirm your password")}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3">
            By clicking <b>Register</b>, you are agreeing to the <a style={{"color":"blue", "cursor": "pointer"}} onClick={handleShow}><b>terms of service</b></a>
            <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Body>
              <GDPR supportEmail={supportEmail}></GDPR>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </Modal>
          </Form.Group>
          <Form.Group className="mb-3">
            <Button
              variant="primary"
              type="submit"
              className="float-end"
              size="sm"
            >
              {t("Register")}
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default RegisterWithMagicCode;
