import React, {useState} from 'react'
import {
    AttachmentButton,
    Avatar,
    AvatarGroup,
    Conversation,
    ConversationHeader,
    InfoButton, MessageInput, Button
} from "@chatscope/chat-ui-kit-react";
import axios from "axios";
import {AUTHORIZED_URL_PREFIX, AxiosConfig, CHAT_SEND_MESSAGE_URL} from "../../Utils/Constants";
import {toast} from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css'

import {Box, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
// import {Button} from "@material-ui/core";

const ConversationMessageInput = (props) => {
    const [newMessage, setNewMessage] = useState('');
    const [attachment, setAttachment] = useState('');
    const [openUploadDialog, setOpenUploadDialog] = useState(false)
    const fileInputRef = React.useRef(null);

    const typingHandler = (value) => {
        setNewMessage(value);

    }

    const sendMessage = async (files, allFiles) => {
        try{

            setNewMessage('');
            const formData = new FormData();
            formData.append('chatId', JSON.stringify(props.selectedChat));
            formData.append('content', JSON.stringify(newMessage));
            formData.append('file', files[0].file);

            const {data} = await axios.post(`${AUTHORIZED_URL_PREFIX}${CHAT_SEND_MESSAGE_URL}`,
                formData,
                AxiosConfig('multipart/form-data'));
            props.socket.emit("new message", data );
            setOpenUploadDialog(false)
            props.setMessages([...props.messages, data.message]);
        }catch (e) {
            toast({
                title: "Error sending the message",
                description: e.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-left",
            });
        }

    }


    const handleAttachment = (event) => {
        const fileUploaded = event.target.files[0];
        const msg = (<div> Hello There</div>)
        setAttachment(fileUploaded);
        // setNewMessage(fileUploaded.name);
        // setNewMessage(msg);
    }


    const handleSubmitUpload = async (files, allFiles) => {
        console.log(files[0].file)

        const currentChat = JSON.stringify(props.selectedChat)

        if(files) {
            try{
                setNewMessage('');
                const formData = new FormData();
                formData.append('file', files[0].file)
                formData.append('chatId', currentChat)
                const {data} = await axios.post(`${AUTHORIZED_URL_PREFIX}${CHAT_SEND_MESSAGE_URL}`,
                    formData,
                    AxiosConfig('multipart/form-data'));

                props.socket.emit("new message", data );
                props.setMessages([...props.messages, data.message]);

            }catch (e) {
                toast({
                    title: "Error sending the message",
                    description: e.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-left",
                });
            }
        }
            console.log(files[0].file);
        allFiles.forEach(f => f.remove())
    }

    return (
        <>
            <div as={MessageInput} style={{
                display: "flex",
                flexDirection: "row",
                borderTop: 'solid 1px #d1dbe3',
                borderRight: 'solid 0px #d1dbe3',
                borderBottom: 'solid 0px #d1dbe3',
                borderLeft: 'solid 0px #d1dbe3',
                marginTop: 'auto',
                color: 'rgba(0,0,0,.87)',
                backgroundColor: '#fff'
            }}>
                <MessageInput
                    onChange={typingHandler}
                    value={newMessage}
                    sendButton
                    attachButton={false}
                    onSend={sendMessage}
                    style={{
                        flexGrow: 1,
                        borderTop: 0,
                        flexShrink: "initial"
                    }} />
                {/*<SendButton onClick={() => handleSend(msgInputValue)} disabled={msgInputValue.length === 0} style={{*/}
                {/*    fontSize: "1.2em",*/}
                {/*    marginLeft: 0,*/}
                {/*    paddingLeft: "0.2em",*/}
                {/*    paddingRight: "0.2em"*/}
                {/*}} />*/}
                {props.showMessengerFeatures ?
                    <AttachmentButton style={{
                        fontSize: "1.2em",
                        paddingLeft: "0.2em",
                        paddingRight: "0.2em"
                    }} onClick={() => setOpenUploadDialog(true)}>
                        {/*onClick={() => fileInputRef.current.click()}*/}
                        <input
                            ref={fileInputRef}
                            type='file'
                            className='d-none'
                            multiple={false}
                            onChange={handleAttachment}
                        />
                    </AttachmentButton> : ''
                }

                <InfoButton
                    onClick = {sendMessage}
                    style={{
                    fontSize: "1.2em",
                    paddingLeft: "0.2em",
                    paddingRight: "0.2em"
                }} />
            </div>
            <Dialog
                open={openUploadDialog}
                maxWidth='md'
                onClose={() => setOpenUploadDialog(false)}
                fullWidth
            >
                <DialogTitle>Upload file</DialogTitle>
                <DialogContent>
                    <Box>
                        <Dropzone
                            maxFiles={1}
                            multiple={false}
                            onSubmit={sendMessage}
                            inputContent="Drag A File or Click to Browse"
                            accept="image/*,video/*,.pdf,.doc,.docx,.txt,.rtf"
                            styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUploadDialog(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConversationMessageInput;