import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import t from "../../Utils/Translation";
import './unitOverview.css';
import {
	AUTHORIZED_URL_PREFIX,
	UNIT_BY_ID_URL,
	UNITIFRAME_URL_WITHOUT_PARAM,
	AxiosConfig
} from '../../Utils/Constants';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import LykioButton from "../common/LykioButton";
import Comments from '../common/Comments';
import registerUnitViewed from '../../Utils/ApiCalls';
import Image from 'react-bootstrap/Image';
import Ratio from 'react-bootstrap/Ratio';
import timeSince from '../../Utils/TimeSince';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import Tooltip from '@mui/material/Tooltip';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import CommentSharpIcon from '@mui/icons-material/CommentSharp';
import AccessAlarmSharpIcon from '@mui/icons-material/AccessAlarmSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import GradeSharpIcon from '@mui/icons-material/GradeSharp';
import StackedBarChartSharpIcon from '@mui/icons-material/StackedBarChartSharp';
import Loading from '../common/Loading';

const UnitOverview = (props) => {
	const context = useContext(LykioContext);
	const storageAuthTokenValue = localStorage.getItem('authToken');
	const user = context.userDetails;
	const tenantProperties = context.tenantProperties;
	const [ isLoading, setIsLoading ] = useState(true);
	const [ unit, setUnit ] = useState([]);
	const { unitId } = useParams();
	const unitIFrameUrl = `${UNITIFRAME_URL_WITHOUT_PARAM}` + unitId;
	const [ totalComments, setTotalComments ] = useState(0);

	useEffect(() => {
		const fetchUnitData = async () => {
			try {
				console.log(
					`UnitOverview-before call: about to make a call to /api/private with token: ${storageAuthTokenValue}`
				);
				const { data } = await axios.post(
					`${AUTHORIZED_URL_PREFIX}${UNIT_BY_ID_URL}`,
					{ unitId },
					AxiosConfig()
				);
				console.log(data)
				setUnit(data.unit);
				setTotalComments(data.unit.comments.length);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				notify(pleaseRefreshError);
			}
		};
		fetchUnitData();
	}, []);

	const updateComments = (comment) => {
		setTotalComments(comment);
	};

	const getParentCategory = (categoryreference)=>{
		let foundParentReference;
		if(tenantProperties && tenantProperties.categories){
			tenantProperties.categories.forEach((category) => {
		   	if(category.reference === categoryreference){
				foundParentReference = category.reference;
		   	}
		   	});
		   if(!foundParentReference){
			tenantProperties.categories.forEach((category) => {
				for(let i = 0; i < category.subCategories.length; i++){
					if(category.subCategories[i].reference === categoryreference){
						foundParentReference = category.reference;
					}
				}
			});
		   }
		}
		console.log(categoryreference);
		return foundParentReference;
	}

	const getFullCategoryName = (categoryreference)=>{
		let fullCategoryName;
		if(tenantProperties && tenantProperties.categories){
			tenantProperties.categories.forEach((category) => {
		   	if(category.reference === categoryreference){
				fullCategoryName = category.name;
		   	}
		   	});
		   if(!fullCategoryName){
			tenantProperties.categories.forEach((category) => {
				for(let i = 0; i < category.subCategories.length; i++){
					if(category.subCategories[i].reference === categoryreference){
						fullCategoryName = category.subCategories[i].name;
					}
				}
			});
		   }
		}
		console.log(categoryreference);
		return fullCategoryName;
	}

	return (
		<div className="lykioccs-main-content" style={{backgroundImage: `url(${unit.coverS3Link})`,backgroundRepeat: 'no-repeat', backgroundSize:'cover' }}>
			{isLoading ? <Loading/> : 
				<div className="lykiocss-unitoverview-allcontent">
				<div className="lykiocss-unitoverview-container">
					<div className="lykiocss-unitoverview-leftpart">
						<div className="lykiocss-unitoverview-infopart">
							<p className="lykiocss-unitoverview-unit-title">{unit.title}</p>
							<div>
								<Ratio aspectRatio="1x1" className="lykiocss-unitoverview-image-ratio">
									<Image
										className="lykiocss-unitoverview-image"
										src={unit.coverS3Link}
										alt="coverImage"
									/>
								</Ratio>
							</div>
							<Breadcrumb style={{marginLeft:'0.5em'}}>
							<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
							{getParentCategory(unit.categoryreference)===unit.categoryreference ?
								<Breadcrumb.Item href={`/library/${getParentCategory(unit.categoryreference)}`}>
								{getFullCategoryName(unit.categoryreference)}
								</Breadcrumb.Item>
							: 
							<React.Fragment>
								<Breadcrumb.Item href={`/library/${getParentCategory(unit.categoryreference)}`}>
								{getFullCategoryName(getParentCategory(unit.categoryreference))}
								</Breadcrumb.Item>
								<Breadcrumb.Item href={`/library/${getParentCategory(unit.categoryreference)}`}>
								{getFullCategoryName(unit.categoryreference)}
								</Breadcrumb.Item>
							</React.Fragment>
							}

							</Breadcrumb>
							<p className="lykiocss-unitoverview-description">
								<strong>{t("Description")}: </strong>
								{unit.description}
							</p>
							{unit.objective &&
								<p className="lykiocss-unitoverview-description">
								<strong>{t("Objective")}: </strong>
								{unit.objective}
								</p>
							}
							<div className="lykiocss-unitoverview-unit-info">
							<Tooltip title={`${unit.duration} minutes long`}>
							<span>
							{unit.duration}
							<AccessAlarmSharpIcon/><span>&nbsp;</span>
							</span>
							</Tooltip>

							<Tooltip title={`${unit.comments ? unit.comments.length : 0} comments`}>
							<span>
							{unit.comments ? unit.comments.length : 0}
							<CommentSharpIcon/><span>&nbsp;</span>
							</span>
							</Tooltip>

							<Tooltip title={`${unit.ratings ? unit.ratings.length : 0} times rated`}>
							<span>
							{unit.ratings ? unit.ratings.length : 0}
							<GradeSharpIcon/><span>&nbsp;</span>
							</span>
							</Tooltip>


							<Tooltip title={`${unit.usage ? unit.usage : 0} times viewed`}>
							<span>
							{unit.usage ? unit.usage : 0}
							<VisibilitySharpIcon/><span>&nbsp;</span>
							</span>
							</Tooltip>


							<Tooltip title={`${unit.impactIndex} Impact Index`}>
							<span>
							{unit.impactIndex}
							<StackedBarChartSharpIcon/><span>&nbsp;</span>
							</span>
							</Tooltip>

							<div className="lykiocss-unitoverview-container-text">
							{t("Created")} {timeSince(unit.creationDate)}
							</div>
							<Link className="lykiocss-unstyled-link" to={unitIFrameUrl}>
							<LykioButton
								onClick={() =>
								console.log('heading to unit iframe')
								}									
								className="lykiocss-unitoverview-lessonbutton"
								variant="primary"
							>
								{t("Start")}
							</LykioButton>
							</Link>
							</div>
						</div>
					</div>
					<div className="lykiocss-unitoverview-rightpart">
						<Comments totalComments={totalComments} updateComments={updateComments} />
					</div>
				</div>
			</div>
			}
		</div>
	);
};

export default UnitOverview;
