import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {
  FRONTEND_REGISTER_URL,
  FRONTEND_SINGLE_TENANCY_REGISTER_URL,
  FRONTEND_REGISTER_USER_URL,
  FRONTEND_REGISTER_USER_WITH_MAGIC_CODE_URL,
  FRONTEND_REGISTER_USER_WITH_ACCESS_CODE_URL,
  FRONTEND_LOGIN_URL,
  FRONTEND_FORGOTPASS_URL,
  FRONTEND_RESENDACTIVATION_URL,
  FRONTEND_PASSWORDRESET_URL,
  FRONTEND_VERIFYACCOUNT_URL,
  FRONTEND_UNSUBSCRIBE_URL,
  API_URL,
  HOME_URL,
  WELCOME_URL,
  STORIES_URL,
  STORIES_FOLLOWING_URL,
  CONTENT_MANAGEMENT_URL,
  CONTENT_MANAGEMENT_CREATE_URL,
  CONTENT_MANAGEMENT_ADDS3_URL,
  UPLOAD_ZIPPED_UNIT_URL,
  CONTENT_MANAGEMENT_MODERATE_URL,
  CONTENT_MANAGEMENT_CATEGORIES_URL,
  USER_MANAGEMENT_URL,
  USER_MANAGEMENT_ADD_USER_URL,
  USER_MANAGEMENT_ADD_GROUP_URL,
  CREATE_URL,
  GUIDES_URL,
  IDEOLOGY_URL,
  SETTINGS_URL,
  SEARCH_URL,
  REPORTS_URL,
  PROFILE_URL,
  NOTIFICATIONS_URL,
  MESSAGES_URL,
  LIBRARY_URL,
  LEADERBOARDS_URL,
  UNITOVERVIEW_URL,
  INITIAL_SETUP_URL,
  CUSTOMIZE_LYKIO_URL,
  RESET_LEADERBOARDS_URL,
  UNITIFRAME_URL,
  PLANS_URL,
  STRIPE_PAYMENT_URL,
  PLAYLISTS_URL,
  UBER_ADMIN_SETTINGS_URL,
  DEPARTMENTS_URL,
  CREATE_ACCESS_CODE_URL,
  PROGRESS_URL,
  GROUP_USERS_PARAM_URL,
  AWARD_URL,
  PLAYLIST_URL,
  USER_EDIT_URL,
  UNIT_EDIT_URL,
  FRONTEND_DOWNLOAD_GAME_URL,
  CONTENT_CREATION,
  CONTENT_CREATION_GUIDE,
  CHAT_URL,
  CONNECTIONS_URL,
  EDUCATIONAL_USER_MANAGEMENT_WITHOUTPARAM_URL,
  EDUCATIONAL_USER_EDIT_WITHOUTPARAM_URL,
  EDUCATIONAL_USER_EDIT_URL, TT_REPORTING, CONTENT_CREATE_SCORM_UNIT
} from './Utils/Constants';

import RegisterScreen from './components/auth/RegisterScreen';
import SingleTenancyRegisterScreen from './components/auth/SingleTenancyRegisterScreen';
import RegisterSimpleUserScreen from './components/auth/RegisterSimpleUserScreen';
import RegisterWithMagicCode from './components/auth/RegisterWithMagicCode';
import RegisterWithAccessCode from './components/auth/RegisterWithAccessCode'; 
import LoginScreen from './components/auth/LoginScreen';
import ForgotPaswordScreen from './components/auth/ForgotPaswordScreen';
import ResetPaswordScreen from './components/auth/ResetPaswordScreen';
import VerifyAccountScreen  from './components/auth/VerifyAccountScreen';
import UnsubscribeScreen from './components/auth/UnsubscribeScreen';
import ResendActivationScreen from './components/auth/ResendActivationScreen';
import API from './components/auth/API';
import CustomizeLykio from './components/admin/CustomizeLykio';
import LykioAuthorizedRoute from './components/routing/LykioAuthorizedRoute';
import LykioOpenRoute from './components/routing/LykioOpenRoute';
import Home from './components/home/Home';
import Stories from './components/stories/Stories';
import StoriesFollowing from './components/stories/StoriesFollowing';
import ContentManagement from './components/contentmanagement/ContentManagement';
import GrapesContentCreation from './components/contentmanagement/GrapesContentCreation';
import ContentModeration from './components/contentmanagement/ContentModeration';
import CategoriesManagement from './components/contentmanagement/CategoriesManagement';
import ContentS3Upload from './components/contentmanagement/ContentS3Upload';
import UserManagement from './components/usermanagement/UserManagement';
import AddUser from './components/usermanagement/AddUser';
import AddGroup from './components/usermanagement/AddGroup';
import Create from './components/stories/Create';
import Guides from './components/guides/Guides';
import Ideology from './components/ideology/Ideology';
import Settings from './components/settings/Settings';
import Search from './components/search/Search';
import Reports from './components/reports/Reports';
import Profile from './components/profile/Profile';
import Notifications from './components/notifications/Notifications';
import Messages from './components/messages/Messages';
import Library from './components/library/Library';
import Leaderboards from './components/leaderboards/Leaderboards';
import UnitOverview from './components/library/UnitOverview';
import UnitIFrame from './components/library/UnitIFrame';
import FirstAdminScreen from './components/admin/FirstAdminScreen';
import Plans from './components/billing/Plans';
import StripeContainer from './components/billing/StripeContainer';
import Playlists from './components/favourites/Playlists';
import UberScreen from './components/admin/UberScreen';
import AddDepartment from './components/usermanagement/AddDepartment';
import AddAccessCode from './components/admin/AddAccessCode';
import Progress from './components/profile/Progress';
import ZipContentUpload from './components/contentmanagement/ZipContentUpload';
import Group from './components/usermanagement/Group';
import AddAward from './components/admin/AddAward';
import AddPlaylist from './components/playlists/AddPlaylist';
import Welcome from './components/home/Welcome';
import EditUser from './components/usermanagement/EditUser';
import EditUnit from './components/contentmanagement/EditUnit';
import ResetLeaderboards from './components/admin/ResetLeaderboards';
import Chat from './components/chat/Chat';
import DownloadPage from './components/auth/DownloadPage';
import ContentCreationDashboard from './components/contentmanagement/ContentCreationDashboard';
import ContentCreationGuide from './components/guides/ContentCreationGuide';
import Connections from "./components/connections/Connections";
import EducationalUserManagement from './components/educational/UserManagement'
import EducationalUserEdit from './components/educational/EditUser';
import TTReporting  from './components/educational/TTReporting';
import ContentScormUpload from "./components/contentmanagement/ContentScormUpload";

const App = () => {

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path={API_URL} element={<LykioOpenRoute><API /></LykioOpenRoute>} />
          <Route path={FRONTEND_REGISTER_URL} element={<LykioOpenRoute><RegisterScreen /></LykioOpenRoute>} />
          <Route path={FRONTEND_SINGLE_TENANCY_REGISTER_URL} element={<LykioOpenRoute><SingleTenancyRegisterScreen /></LykioOpenRoute>} />
          <Route path={FRONTEND_REGISTER_USER_URL} element={<LykioOpenRoute><RegisterSimpleUserScreen /></LykioOpenRoute>} />
          <Route path={FRONTEND_REGISTER_USER_WITH_MAGIC_CODE_URL} element={<LykioOpenRoute><RegisterWithMagicCode /></LykioOpenRoute>} />
          <Route path={FRONTEND_REGISTER_USER_WITH_ACCESS_CODE_URL} element={<LykioOpenRoute><RegisterWithAccessCode /></LykioOpenRoute>} />
          <Route path={FRONTEND_LOGIN_URL} element={<LykioOpenRoute><LoginScreen /></LykioOpenRoute>} />
          <Route path={FRONTEND_FORGOTPASS_URL} element={<LykioOpenRoute><ForgotPaswordScreen /></LykioOpenRoute>} />
          <Route path={FRONTEND_RESENDACTIVATION_URL} element={<LykioOpenRoute><ResendActivationScreen /></LykioOpenRoute>} />
          <Route path={FRONTEND_PASSWORDRESET_URL} element={<LykioOpenRoute><ResetPaswordScreen /></LykioOpenRoute>} />
          <Route path={FRONTEND_VERIFYACCOUNT_URL} element={<LykioOpenRoute><VerifyAccountScreen /></LykioOpenRoute>} />
          <Route path={FRONTEND_UNSUBSCRIBE_URL} element={<LykioOpenRoute><UnsubscribeScreen /></LykioOpenRoute>} />
          <Route path={FRONTEND_DOWNLOAD_GAME_URL} element={<LykioOpenRoute><DownloadPage /></LykioOpenRoute>} />
          <Route path={HOME_URL} element={<LykioAuthorizedRoute><Home /></LykioAuthorizedRoute>} />
          <Route path={WELCOME_URL} element={<LykioAuthorizedRoute><Welcome /></LykioAuthorizedRoute>} />
          <Route path={STORIES_URL} element={<LykioAuthorizedRoute><Stories /></LykioAuthorizedRoute>} />
          <Route path={STORIES_FOLLOWING_URL} element={<LykioAuthorizedRoute><StoriesFollowing /></LykioAuthorizedRoute>} />
          <Route path={CONTENT_MANAGEMENT_URL} element={<LykioAuthorizedRoute><ContentManagement /></LykioAuthorizedRoute>} />
          <Route path={CONTENT_CREATION} element={<LykioAuthorizedRoute><ContentCreationDashboard /></LykioAuthorizedRoute>} />
          <Route path={CONTENT_CREATION_GUIDE} element={<LykioAuthorizedRoute><ContentCreationGuide /></LykioAuthorizedRoute>} />
          <Route path={CONTENT_MANAGEMENT_CREATE_URL} element={<LykioAuthorizedRoute><GrapesContentCreation /></LykioAuthorizedRoute>} />
          <Route path={CONTENT_MANAGEMENT_ADDS3_URL} element={<LykioAuthorizedRoute><ContentS3Upload /></LykioAuthorizedRoute>} />
          <Route path={CONTENT_CREATE_SCORM_UNIT} element={<LykioAuthorizedRoute><ContentScormUpload /></LykioAuthorizedRoute>} />
          <Route path={UPLOAD_ZIPPED_UNIT_URL} element={<LykioAuthorizedRoute><ZipContentUpload /></LykioAuthorizedRoute>} />
          <Route path={CONTENT_MANAGEMENT_MODERATE_URL} element={<LykioAuthorizedRoute><ContentModeration /></LykioAuthorizedRoute>} />
          <Route path={CONTENT_MANAGEMENT_CATEGORIES_URL} element={<LykioAuthorizedRoute><CategoriesManagement /></LykioAuthorizedRoute>} />
          <Route path={UNIT_EDIT_URL} element={<LykioAuthorizedRoute><EditUnit /></LykioAuthorizedRoute>}/>          
          <Route path={USER_MANAGEMENT_URL} element={<LykioAuthorizedRoute><UserManagement /></LykioAuthorizedRoute>}/>
          <Route path={USER_MANAGEMENT_ADD_USER_URL} element={<LykioAuthorizedRoute><AddUser /></LykioAuthorizedRoute>}/>
          <Route path={USER_EDIT_URL} element={<LykioAuthorizedRoute><EditUser /></LykioAuthorizedRoute>}/>
          <Route path={USER_MANAGEMENT_ADD_GROUP_URL} element={<LykioAuthorizedRoute><AddGroup /></LykioAuthorizedRoute>}/>
          <Route path={CREATE_URL} element={<LykioAuthorizedRoute><Create /></LykioAuthorizedRoute>} />
          <Route path={GUIDES_URL} element={<LykioAuthorizedRoute><Guides /></LykioAuthorizedRoute>} />
          <Route path={IDEOLOGY_URL} element={<LykioAuthorizedRoute><Ideology /></LykioAuthorizedRoute>} />
          <Route path={SETTINGS_URL} element={<LykioAuthorizedRoute><Settings /></LykioAuthorizedRoute>} />
          <Route path={SEARCH_URL} element={<LykioAuthorizedRoute><Search /></LykioAuthorizedRoute>} />
          <Route path={REPORTS_URL} element={<LykioAuthorizedRoute><Reports /></LykioAuthorizedRoute>} />
          <Route path={PROFILE_URL} element={<LykioAuthorizedRoute><Profile /></LykioAuthorizedRoute>} />
          <Route path={PROGRESS_URL} element={<LykioAuthorizedRoute><Progress /></LykioAuthorizedRoute>} />
          <Route path={NOTIFICATIONS_URL} element={<LykioAuthorizedRoute><Notifications /></LykioAuthorizedRoute>} />
          <Route path={MESSAGES_URL} element={<LykioAuthorizedRoute><Messages /></LykioAuthorizedRoute>} />
          <Route path={LIBRARY_URL} element={<LykioAuthorizedRoute><Library /></LykioAuthorizedRoute>} />
          <Route path={LEADERBOARDS_URL} element={<LykioAuthorizedRoute><Leaderboards /></LykioAuthorizedRoute>} />
          <Route path={UNITOVERVIEW_URL} element={<LykioAuthorizedRoute><UnitOverview /></LykioAuthorizedRoute>} />
          <Route path={UNITOVERVIEW_URL} element={<LykioAuthorizedRoute><UnitOverview /></LykioAuthorizedRoute>} />
          <Route path={UNITIFRAME_URL} element={<LykioAuthorizedRoute><UnitIFrame /></LykioAuthorizedRoute>} />
          <Route path={INITIAL_SETUP_URL} element={<LykioAuthorizedRoute><FirstAdminScreen /></LykioAuthorizedRoute>} />
          <Route path={CUSTOMIZE_LYKIO_URL} element={<LykioAuthorizedRoute><CustomizeLykio /></LykioAuthorizedRoute>} />
          <Route path={RESET_LEADERBOARDS_URL} element={<LykioAuthorizedRoute><ResetLeaderboards /></LykioAuthorizedRoute>} />
          <Route path={PLANS_URL} element={<LykioAuthorizedRoute><Plans /></LykioAuthorizedRoute>} />
          <Route path={STRIPE_PAYMENT_URL} element={<LykioAuthorizedRoute><StripeContainer /></LykioAuthorizedRoute>} />
          <Route path={PLAYLISTS_URL} element={<LykioAuthorizedRoute><Playlists /></LykioAuthorizedRoute>} />
          <Route path={UBER_ADMIN_SETTINGS_URL} element={<LykioAuthorizedRoute><UberScreen /></LykioAuthorizedRoute>} />
          <Route path={DEPARTMENTS_URL} element={<LykioAuthorizedRoute><AddDepartment /></LykioAuthorizedRoute>} />
          <Route path={CREATE_ACCESS_CODE_URL} element={<LykioAuthorizedRoute><AddAccessCode /></LykioAuthorizedRoute>} />
          <Route path={GROUP_USERS_PARAM_URL} element={<LykioAuthorizedRoute><Group /></LykioAuthorizedRoute>} />
          <Route path={AWARD_URL} element={<LykioAuthorizedRoute><AddAward /></LykioAuthorizedRoute>} />
          <Route path={PLAYLIST_URL} element={<LykioAuthorizedRoute><AddPlaylist /></LykioAuthorizedRoute>} />
          <Route path={CHAT_URL} element={<LykioAuthorizedRoute><Chat/></LykioAuthorizedRoute>}/>
          <Route path={CONNECTIONS_URL} element={<LykioAuthorizedRoute><Connections/></LykioAuthorizedRoute>}/>
          <Route path={EDUCATIONAL_USER_MANAGEMENT_WITHOUTPARAM_URL} element={<LykioAuthorizedRoute><EducationalUserManagement/></LykioAuthorizedRoute>}/>
          <Route path={EDUCATIONAL_USER_EDIT_URL} element={<LykioAuthorizedRoute><EducationalUserEdit/></LykioAuthorizedRoute>}/>
          <Route path={TT_REPORTING} element={<LykioAuthorizedRoute><TTReporting/></LykioAuthorizedRoute>}/>
        </Routes>
        </div>
    </Router>
    
  );
}

export default App;