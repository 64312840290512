import React, { useState, useEffect, useContext } from "react";
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import { Button, Card, CardGroup, Col, Container, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import t from "../../Utils/Translation";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import axios from "axios";
import {
  AUTHORIZED_URL_PREFIX,
  AxiosConfig,
  CONNECTIONS_CREATE_ADMIN_URL,
  CONNECTIONS_CREATE_CLASS, CONNECTIONS_ENROLL_TO_CLASS,
  CONNECTIONS_ENROLL_TO_SCHOOL_URL,
  CONNECTIONS_URL, NOTIFICATION_SEVERITY_SUCCESS, NOTIFICATION_SEVERITY_WARNING,
  ROLE_ADMIN,
  ROLE_TEACHER,
  ROLE_UBER,
  ROLE_USER,
} from "../../Utils/Constants";
import Loading from "../common/Loading";
import LykioButton from "../common/LykioButton";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import SupportEmail from "../common/SupportEmail";

const Connections = () => {
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(true);
  const [schoolID, setSchoolID] = useState({});
  const [role, setRole] = useState("");
  const [groups, setGroups] = useState([]);

  const [schoolName, setSchoolName] = useState("");
  const [magicSecret, setMagicSecret] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupReference, setGroupReference] = useState('');

  const fetchConnectionsData = async () => {
    try {
      const { data } = await axios.get(
          `${AUTHORIZED_URL_PREFIX}${CONNECTIONS_URL}`,
          AxiosConfig()
      );
      console.log(data)
      if (!data.success) {
        notify(pleaseRefreshError);
      } else {
        console.log(data);
        setRole(data.role);
        setSchoolID(data.school);
        setGroups(data.groups);
        setMagicSecret(data.magicSecret);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchConnectionsData();
  }, []);

  const makeTeacherAdmin = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.post(
          `${AUTHORIZED_URL_PREFIX}${CONNECTIONS_CREATE_ADMIN_URL}`,
          { school: schoolName },
          AxiosConfig()
      );
      if(data.success === true) {
        fetchConnectionsData();
      }

    } catch (e) {
      console.log(e);
    }
  };

  const enrollToSchool = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
          `${AUTHORIZED_URL_PREFIX}${CONNECTIONS_ENROLL_TO_SCHOOL_URL}`,
          { magicSecret: magicSecret },
          AxiosConfig()
      );
      if(data.success === true) {
        fetchConnectionsData();
        notify({
          title: `Your are now enrolled to a School.`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        }) ;
      } else if(data.success === false) {
        notify({
          title: `The MagicSecret was not found.`,
          severity: NOTIFICATION_SEVERITY_WARNING,
          creationDate: "",
        }) ;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const enrollToClass = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
          `${AUTHORIZED_URL_PREFIX}${CONNECTIONS_ENROLL_TO_CLASS}`,
          { groupReference: groupName },
          AxiosConfig()
      );

      if(data.success === true) {
        setGroupName('');
        fetchConnectionsData();
        notify({
          title: `Your are now enrolled to a class.`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        }) ;
      } else if(data.success === false) {
        notify({
          title: `Your class ID was not found.`,
          severity: NOTIFICATION_SEVERITY_WARNING,
          creationDate: "",
        }) ;
      }
    }catch (e) {
      console.log(e.error)
    }
  }

  const createClass = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
          `${AUTHORIZED_URL_PREFIX}${CONNECTIONS_CREATE_CLASS}`,
          { group: groupName },
          AxiosConfig()
      );
      if(data.success === true) {
        setGroupName('');
        fetchConnectionsData();
        notify({
          title: `Your class was successfully generated.`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        }) ;

      }

    } catch (e) {
      console.log(e);
    }
  };

  return (
      <div className="lykioccs-main-content">
        {isLoading ? (
            <Loading />
        ) : (
            <div className="lykiocss-center lykiocss-margin-top-2em">
              <Container>
                {/*<CardGroup>*/}
                {role === ROLE_ADMIN && (
                    <>
                      <Row>
                        <CardGroup>
                          {schoolID ? (
                              <>
                                <Card>
                                  <Card.Body>
                                    <Card.Title className={"text-center"}>
                                      School
                                    </Card.Title>
                                    <hr />
                                    <Card.Text className={"text-center"}>
                                      You are the School Administrator of :{" "}
                                      <strong>{schoolID}</strong>
                                    </Card.Text>
                                    <Card.Text className={"text-center"}>
                                      Your School id is :{" "}
                                      <strong>{magicSecret}</strong>
                                    </Card.Text>
                                    <Card.Text className={"text-center"}>
                                    *You can share the school id with your peers so that they can enrol to the school when registering.
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                                <Card>
                                  <Form>
                                    <Card.Body>
                                      <Card.Title className={"text-center"}>
                                        Your Classes
                                      </Card.Title>
                                      <Card.Text className={"text-center"}>
                                      *You can share the class reference with your student so that they can enrol to your class after their registration.
                                      </Card.Text>
                                      <hr />
                                      {groups.length > 0 && (
                                          <Card.Text>
                                            <table className="table table-hover">
                                              <thead>
                                              <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Class Name</th>
                                                <th scope="col">Class Reference</th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {groups.map((group, index) => {
                                                return (
                                                    <tr>
                                                      <th scope="row">{index + 1 }</th>
                                                      <td>{group.name}</td>
                                                      <td>{group.reference}</td>
                                                    </tr>
                                                )
                                              })}

                                              </tbody>
                                            </table>
                                          </Card.Text>
                                      )}
                                      <Card.Title className={"text-center"}>
                                        Create you own Class
                                      </Card.Title>
                                      <Form>
                                        <Form.Group
                                            className="mb-3 mt-3"
                                            controlId="title"
                                        >
                                          <FloatingLabel
                                              controlId="floatingtitle"
                                              label={t(`Enter your class Name`)}
                                          >
                                            <Form.Control
                                                type="text"
                                                required
                                                onChange={(e) =>
                                                    setGroupName(e.target.value)
                                                }
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Form>
                                    </Card.Body>
                                    <Card.Footer>
                                      <LykioButton
                                          type="submit"
                                          className="float-end"
                                          style={{marginBottom:'0.5em'}}
                                          disabled={!groupName}
                                          onClick={(e) => createClass(e)}
                                      >
                                        Create
                                      </LykioButton>
                                    </Card.Footer>
                                  </Form>
                                </Card>
                              </>
                          ) : ('Something went wrong')}
                        </CardGroup>
                      </Row>
                    </>
                )}
                {role === ROLE_TEACHER && (
                    <>
                      <Row>
                        <CardGroup>
                          {schoolID ? (
                              <>
                                <Card>
                                  <Form>
                                    <Card.Body>
                                      <Card.Title className={"text-center"}>
                                        School
                                      </Card.Title>
                                      <hr />
                                      <Card.Text className={"text-center"}>
                                        Your School Name is :{" "}
                                        <strong>{schoolID}</strong>
                                      </Card.Text>
                                      <Card.Text className={"text-center"}>
                                        Your School id is :{" "}
                                        <strong>{magicSecret}</strong>
                                      </Card.Text>
                                    </Card.Body>
                                  </Form>
                                </Card>
                                <Card>
                                  <Form>
                                    <Card.Body>
                                      <Card.Title className={"text-center"}>
                                        Your Classes
                                      </Card.Title>
                                      <Card.Text className={"text-center"}>
                                      *You can share the class reference with your student so that they can enrol to your class after their registration.
                                      </Card.Text>
                                      <hr />
                                      {groups.length > 0 && (
                                          <Card.Text>
                                            <table className="table table-hover">
                                              <thead>
                                              <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Class Name</th>
                                                <th scope="col">Class Reference</th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {groups.map((group, index) => {
                                                return (
                                                    <tr>
                                                      <th scope="row">{index + 1 }</th>
                                                      <td>{group.name}</td>
                                                      <td>{group.reference}</td>
                                                    </tr>
                                                )
                                              })}

                                              </tbody>
                                            </table>
                                          </Card.Text>
                                      )}
                                      <Card.Title className={"text-center"}>
                                        Create you own Class
                                      </Card.Title>
                                      <Form>
                                        <Form.Group
                                            className="mb-3 mt-3"
                                            controlId="title"
                                        >
                                          <FloatingLabel
                                              controlId="floatingtitle"
                                              label={t(`Enter your class Name`)}
                                          >
                                            <Form.Control
                                                type="text"
                                                required
                                                onChange={(e) =>
                                                    setGroupName(e.target.value)
                                                }
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Form>
                                    </Card.Body>
                                    <Card.Footer>
                                      <LykioButton
                                          type="submit"
                                          className="float-end"
                                          style={{marginBottom:'0.5em'}}
                                          disabled={!groupName}
                                          onClick={(e) => createClass(e)}
                                      >
                                        Create
                                      </LykioButton>
                                    </Card.Footer>
                                  </Form>
                                </Card>
                              </>
                          ) : (
                              <>
                                <Card>
                                  <Form>
                                    <Card.Body>
                                      <Card.Title className={"text-center"}>
                                        School
                                      </Card.Title>
                                      <hr />
                                      <Card.Text className={"text-center"}>
                                        Become a School Admin
                                      </Card.Text>

                                      <Form.Group
                                          className="mb-3 mt-3"
                                          controlId="title"
                                      >
                                        <FloatingLabel
                                            controlId="floatingtitle"
                                            label={t(`Enter your School name`)}
                                        >
                                          <Form.Control
                                              type="text"
                                              required
                                              onChange={(e) =>
                                                  setSchoolName(e.target.value)
                                              }
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Card.Body>
                                    <Card.Footer>
                                      <LykioButton
                                          type="submit"
                                          className="float-end"
                                          style={{marginBottom:'0.5em'}}
                                          onClick={(e) => makeTeacherAdmin(e)}
                                      >
                                        Generate
                                      </LykioButton>
                                    </Card.Footer>
                                  </Form>
                                </Card>
                                <div className="d-flex align-items-center mx-2">
                                  <div> OR </div>
                                </div>
                                <Card>
                                  <Form>
                                    <Card.Body>
                                      <Card.Title className={"text-center"}>
                                        Enrol to your school
                                      </Card.Title>
                                      <hr />
                                      <Card.Text className={"text-center"}>
                                        Become a teacher to your school
                                      </Card.Text>

                                      <Form.Group
                                          className="mb-3 mt-3"
                                          controlId="title"
                                      >
                                        <FloatingLabel
                                            controlId="floatingtitle"
                                            label={t(`Enter Your School id`)}
                                        >
                                          <Form.Control
                                              type="text"
                                              required
                                              onChange={(e) =>
                                                  setMagicSecret(e.target.value)
                                              }
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Card.Body>
                                    <Card.Footer>
                                      <LykioButton
                                          type="submit"
                                          className="float-end"
                                          style={{marginBottom:'0.5em'}}
                                          onClick={(e) => enrollToSchool(e)}
                                      >
                                        Enrol
                                      </LykioButton>
                                    </Card.Footer>
                                  </Form>
                                </Card>
                              </>
                          )}
                        </CardGroup>
                      </Row>
                    </>
                )}
                {role === ROLE_USER && (
                  <div className="lykiocss-center lykiocss-margin-top-2em">
                    <Row>
                      <CardGroup style={{minWidth:'25em'}}>
                        {schoolID ? (
                            <>
                              {groups.length > 0 && (
                                  <Card>
                                    <Card.Body>
                                      <Card.Title className={"text-center"}>
                                        Your Class
                                      </Card.Title>
                                      <hr />
                                      <Card.Text className={"text-center"}>
                                        Your School Name is : <strong>{schoolID}</strong>
                                      </Card.Text>
                                      <Card.Text className={"text-center"}>
                                        Your Class is: {groups[0].name}
                                      </Card.Text>

                                    <Card.Text className={"text-center"}>
                                      Change your class
                                    </Card.Text>
                                    <Form>
                                      <Form.Group
                                          className="mb-3 mt-3"
                                          controlId="title"
                                      >
                                        <FloatingLabel
                                            controlId="floatingtitle"
                                            label={t(`Enter Class ID`)}
                                        >
                                          <Form.Control
                                              type="text"
                                              required
                                              onChange={(e) => setGroupName(e.target.value)}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Form>
                                    </Card.Body>
                                    <Card.Footer>
                                      <LykioButton 
                                        type='submit'
                                        className="float-end"
                                        style={{marginBottom:'0.5em'}}
                                        disabled={!groupName} 
                                        onClick={(e) => enrollToClass(e)}>
                                          Change Class
                                      </LykioButton>
                                    </Card.Footer>
                                  </Card>
                              )}
                            </>
                        ) : (
                            <Card>
                              <Form>
                                <Card.Body>
                                  <Card.Title className={"text-center"}>
                                    Your Class
                                  </Card.Title>
                                  <hr />
                                  <Card.Text className={"text-center"}>
                                    Enrol to Your Class
                                  </Card.Text>
                                  <Form.Group
                                      className="mb-3 mt-3"
                                      controlId="title"
                                  >
                                    <FloatingLabel
                                        controlId="floatingtitle"
                                        label={t(`Enter Class ID`)}
                                    >
                                      <Form.Control
                                          type="text"
                                          required
                                          onChange={(e) => setGroupName(e.target.value)}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Card.Body>
                                <Card.Footer>
                                  <LykioButton 
                                  type='submit'
                                  className="float-end"
                                  style={{marginBottom:'0.5em'}} 
                                  onClick={(e) => enrollToClass(e)}>
                                    Enroll
                                  </LykioButton>
                                </Card.Footer>
                              </Form>
                            </Card>
                        )}
                      </CardGroup>
                      </Row> 
                  </div>
                )}
              </Container>
            </div>
        )}
      <SupportEmail/>
      </div>
  );
};

export default Connections;
