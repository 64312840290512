export const LANGUAGES_ARRAY = [ {code:'en',name:'English'}, {code:'el',name:'Greek'}, {code:'es',name:'Spanish'} ];
export const BACKEND_BASE_URL = 'http://localhost:5000';
export const GOOGLE_CLIENT_ID = '1017808396535-1k903c5vnpeqp1b1545024t6jrgje5d5.apps.googleusercontent.com';

//URLS
export const HOME_URL = '/';
export const WELCOME_URL = '/user-guide';
export const API_URL = '/api/docs';
export const HOME_DATA_URL = '/homedata';
export const AUTH_TOKEN_NAME = 'authToken';
export const FRONTEND_REGISTER_URL = '/register';
export const FRONTEND_SINGLE_TENANCY_REGISTER_URL = '/lykio-register';
export const FRONTEND_REGISTER_USER_WITH_MAGIC_CODE_URL = '/user-registration-with-code';
export const FRONTEND_REGISTER_USER_WITH_ACCESS_CODE_URL = '/user-registration-with-access-code';
export const FRONTEND_REGISTER_USER_URL = '/user-registration/:isTeacher/:email/:group';
export const FRONTEND_LOGIN_URL = '/login';
export const FRONTEND_FORGOTPASS_URL = '/forgotpassword';
export const FRONTEND_RESENDACTIVATION_URL = '/resendactivation';
export const FRONTEND_PASSWORDRESET_URL = '/passwordreset/:resetToken';
export const FRONTEND_VERIFYACCOUNT_URL = '/verifyaccount/:verificationToken';
export const FRONTEND_UNSUBSCRIBE_URL = '/unsubscribe/:email';
export const FRONTEND_DOWNLOAD_GAME_URL = '/download-game';
export const LOGIN_URL = '/api/auth/login';
export const REGISTER_URL = '/api/auth/register';
export const SINGLE_TENANCY_REGISTER_URL = '/api/auth/lykio-register';
export const REGISTER_USER_URL = '/api/auth/user-registration';
export const REGISTER_USER_WITH_MAGIC_CODE_URL = '/api/auth/user-registration-with-code';
export const REGISTER_USER_WITH_ACCESS_CODE_URL = '/api/auth/user-registration-with-access-code';
export const LOGIN_WITH_GOOGLE = '/api/auth/googlelogin';
export const LOGIN_WITH_AZURE_AD = '/api/auth/azure-ad-login';
export const FORGOTPASS_URL = '/api/auth/forgotpassword';
export const RESETPASS_URL = '/api/auth/resetpassword';
export const RESENDACTIVATION_URL = '/api/auth/resendactivation';
export const VERIFYACCOUNT_URL = '/api/auth/verifyaccount';
export const UNSUBSCRIBE_URL = '/api/auth/unsubscribe';
export const PUBLIC_URL_PREFIX = '/api/auth';
export const AUTHORIZED_URL_PREFIX = '/api/private';
export const STORIES_URL = '/stories';
export const NEXT_SET_OF_STORIES_URL = '/next-set-of-stories';
export const STORIES_FOLLOWING_URL= '/myfeed';
export const CONTENT_MANAGEMENT_URL = '/contentmanagement';
export const CONTENT_MANAGEMENT_CREATE_URL = '/create-new-content';
export const CONTENT_CREATE_SCORM_UNIT = '/create-scorm-unit';
export const GET_SCORM_LAUNCH_URL = '/get-scorm-unit-launch-url';

export const CONTENT_MANAGEMENT_ADDS3_URL = '/add-new-content';
export const CONTENT_MANAGEMENT_MODERATE_URL = '/manage-content/:type';
export const CONTENT_MANAGEMENT_MODERATE_WITHOUTPARAM_URL = '/manage-content';
export const CONTENT_MANAGEMENT_CATEGORIES_URL = '/manage-categories';
export const CONTENT_MANAGEMENT_ADD_SUBCATEGORY_URL = '/add-subcategory';
export const CONTENT_MANAGEMENT_MODIFY_CATEGORY_URL = '/modify-category';
export const USER_MANAGEMENT_WITHOUTPARAM_URL = '/usermanagement';
export const USER_MANAGEMENT_URL = '/usermanagement/:groupReference';
export const GET_FULL_USER_EDIT_INFO_URL='/get-user-edit-info';
export const USER_EDIT_WITHOUTPARAM_URL = '/edit-user';
export const USER_EDIT_URL = '/edit-user/:userId';
export const GET_FULL_UNIT_EDIT_INFO_URL='/get-unit-edit-info';
export const UNIT_EDIT_WITHOUTPARAM_URL = '/edit-unit';
export const UNIT_EDIT_URL = '/edit-unit/:unitId';
export const USER_MANAGEMENT_ADD_USER_URL = '/add-new-user';
export const USER_MANAGEMENT_ADD_GROUP_URL = '/add-new-group';
export const USER_MANAGEMENT_ADD_DEPT_URL = '/add-new-department';
export const CREATE_URL = '/create';
export const GUIDES_URL = '/guides';
export const IDEOLOGY_URL = '/ideology';
export const SETTINGS_URL = '/settings';
export const UPDATE_SETTINGS_URL = '/update-settings';
export const SEARCH_URL = '/search';
export const REPORTS_URL = '/reports';
export const PROFILE_URL_WITHOUT_PARAM = '/profile';
export const PROFILE_URL = '/profile/:userId';
export const PROGRESS_URL_WITHOUT_PARAM = '/progress';
export const PROGRESS_URL = '/progress/:userId';
export const EDIT_PROFILE_URL = '/edit-profile';
export const NOTIFICATIONS_URL = '/notifications';
export const MESSAGES_URL = '/messages';
export const LIBRARY_URL_WITHOUT_PARAM = '/library';
export const LIBRARY_URL = '/library/:categoryreference/:libraryType';
export const LEADERBOARDS_URL = '/leaderboards';
export const UNITOVERVIEW_URL_WITHOUT_PARAM = '/unit-overview/';
export const UNITOVERVIEW_URL = '/unit-overview/:unitId';
export const UNITIFRAME_URL_WITHOUT_PARAM = '/unit/';
export const UNITIFRAME_URL = '/unit/:unitId';
export const INITIAL_SETUP_URL = '/initial-setup';
export const UBER_ADMIN_SETTINGS_URL = '/uber-settings';
export const UBER_ADMIN_MIGRATEUSERSGROUPS_URL = '/uber-admin-groups-migrations';
export const CUSTOMIZE_LYKIO_URL = '/customize-lykio';
export const RESET_LEADERBOARDS_URL = '/reset-leaderboards';
export const GET_CONTEXT = '/context';
export const CATEGORIES_URL = '/categories';
export const GROUPS_URL = '/groups';
export const DEPARTMENTS_URL = '/departments';
export const CHANGE_TENANT_THEME_URL = '/change-tenant-theme';
export const ADD_COMMENT_URL = '/add-comment';
export const ADD_RATING_URL = '/add-rating';
export const GET_RATINGS_URL = '/ratings';
export const READ_NOTIFICATION_URL = '/read-notification';
export const DELETE_NOTIFICATION_URL = '/delete-notification';
export const VIEW_UNIT_URL = '/view-unit';
export const UPLOAD_GRAPES_UNIT_URL = '/upload-grapes-unit';
export const UPLOAD_ZIPPED_UNIT_URL = '/upload-zipped-unit';
export const FOLLOW_USER_URL = '/follow-user';
export const MODERATE_UNIT_URL = '/moderate-unit';
export const PLANS_URL = '/pricing-plans';
export const USER_BY_ID_URL = '/user-by-id';
export const DELETE_NOTIFICATION_BY_ID = '/delete-notification';
export const MODERATE_USER = '/moderate-user';
export const SEND_ACTIVATION_URL = '/send-activation';
export const IS_UNIT_FAVOURITE = '/is-favorite';
export const UPDATE_FAVOURITES = '/update-favorites';
export const PLAYLISTS_URL = '/myplaylists';
export const CREATE_ACCESS_CODE_URL = '/create-access-code';
export const ACCESS_CODES = '/access-codes';
export const TENANT_SETTINGS = '/tenant-settings';
export const GROUP_USERS_URL = '/group-users';
export const GROUP_USERS_PARAM_URL = '/group-users/:groupreference';
export const AWARD_URL = '/award';
export const PLAYLIST_URL = '/playlist';
export const ASSIGN_PLAYLIST_URL = '/assign-playlist';
export const MASS_USER_UPLOAD_URL = '/upload-users';
export const MASS_ACCESS_CODES_UPLOAD_URL = '/upload-access-codes';
export const CONTENT_CREATION = '/content-creation';
export const CONTENT_CREATION_GUIDE = '/content-creation-guide';
export const CHAT_URL = '/chat'
export const CHAT_MESSAGE_URL = '/chat-message';
export const CHAT_SEND_MESSAGE_URL = '/send-message';
export const SEARCH_USERS_URL = '/search-users';
export const CHAT_UNREAD_URL = '/chat-read-state';
export const CHAT_GROUP_URL = '/chat-group';
export const REGISTER_ACCESS_CODE = '/register-access-code';


// Education mode urls
export const CONNECTIONS_URL = '/connections';
export const CONNECTIONS_CREATE_ADMIN_URL = '/connections-create-admin';
export const CONNECTIONS_ENROLL_TO_SCHOOL_URL = '/connections-enroll-to-school';
export const CONNECTIONS_CREATE_CLASS = '/connections-create-class';
export const CONNECTIONS_ENROLL_TO_CLASS = '/connections-enroll-to-class';
export const EDUCATIONAL_USER_MANAGEMENT_WITHOUTPARAM_URL = '/school-user-management'
export const EDUCATIONAL_USER_MANAGEMENT_URL = '/school-user-management/:groupReference'
export const EDUCATIONAL_USER_EDIT_WITHOUTPARAM_URL = '/school-edit-user';
export const EDUCATIONAL_USER_EDIT_URL = '/school-edit-user/:userId'
export const GET_ALL_TENANTS = '/get-tenants'
export const GET_USERS_PER_GROUP = '/get-users-per-group';
export const GET_GROUPS_PER_TENANT = '/get-groups-per-tenant';
export const TT_REPORTING = '/tt-reporting';



// Backend specific urls
export const UNIT_BY_ID_URL = '/unit-by-id';
export const GET_ACTIVE_TENANT_USERS = '/active-tenant-users';

// User Roles
export const ROLE_USER = 'user'
export const ROLE_TEACHER = 'teacher'
export const ROLE_ADMIN = 'admin'
export const ROLE_UBER = 'uber'
export const ROOT_TENANT = 'uber'

// Lirbrary
// Library types
export const LIBRARY_UNITS = 'units';
export const LIBRARY_RESOURCES = 'My Resources';
export const LIBRARY_PROGRAMS = 'My Interactive Books';
export const LIBRARY_WORKBOOK = 'WorkBook';
export const LIBRARY_STUDENTBOOK = 'StudentBook';
export const LIBRARY_TEACHERBOOK = 'TeacherBook';
export const LIBRARY_AUDIO = 'Audio';
export const LIBRARY_VIDEO = 'Video';
export const LIBRARY_FLASHCARDS = 'Flashcards';
export const LIBRARY_EXTRA_MATERIAL = 'ExtraMaterial';


// Backend model related stuff
export const NOTIFICATION_SEVERITY_INFO = 'info';
export const NOTIFICATION_SEVERITY_SUCCESS = 'success';
export const NOTIFICATION_SEVERITY_WARNING = 'warning';

//Backend Status states
export const PENDING_UNIT = 'pending';
export const APPROVED_UNIT = 'approved';
export const REJECTED_UNIT = 'rejected';
export const ACTIVE_UNIT = 'active';
export const INACTIVE_UNIT = 'inactive';
export const PENDING_USER = 'pending';
export const ACTIVE_USER = 'active';
export const LOCKED_USER = 'locked';

// Untranslated texts
export const TEXTS_LOGIN = 'Login';
export const TEXTS_PASS = 'Password:';
export const TEXTS_EMAIL = 'Email:';
export const TEXTS_FORGOTPASS = 'Forgot Password';
export const TEXTS_NOACCOUNT = `Don't have an account?`;
export const TEXTS_REGISTER = 'Register Here';
export const TEXTS_MISSED_ACTIVATION = 'Missed the activation email?';
export const TEXTS_RESEND_ACTIVATION = 'Resend Activation Email';
export const TEXTS_ERROR_ON_LOGIN =
	'There was an error with your credentials. Retype them carefully and if the problem persists, use the forgot password option or contact your administrator.';
export const TEXTS_ERROR_ON_GOOGLE_LOGIN = 'There was an error while logging with your Google account.';

export const NOTIFICATION_STORY_UPLOAD = 'NotificationStoryUpload';

//AxiosConfig
export const AxiosConfig = (contentType) => {
	return {
		headers: {
			'Content-Type': contentType ? contentType : 'application/json',
			Authorization: `Bearer ${localStorage.getItem('authToken')}`
		}
	};
};

export const AxiosConfigNoAuth = (contentType) => {
	return {
		header: {
			'Content-Type': contentType ? contentType : 'application/json'
		}
	};
};

// Billing
export const CURRENCY_US_DOLLAR = 'USD';
export const CURRENCY_UK_POUND = 'GBP';
export const CURRENCY_EURO = 'EUR';
export const LYKIO_FREE_PLAN = 'free';
export const LYKIO_STANDARD_PLAN = 'standard';
export const LYKIO_PLUS_PLAN = 'plus';
export const LYKIO_MONTHLY_PAYMENT = 'montlhy';
export const LYKIO_YEARLY_PAYMENT = 'yearly';
export const STRIPE_PUBLISHABLE_KEY =
	'pk_test_51KCpGHGgwkIwhBfkmjAQwpG8nEJt64yxzN0bPTxXNEu8uVtWn4oTAFQxh3emHmYgQap3xWhHy82mp7CUrWyYognE00xxCp905q';
export const STRIPE_PAYMENT_URL = '/stripe-payment';

// Badges
export const GM_INITIAL_BADGES_EXPLORER = 'Explorer of Ideas';
export const GM_INITIAL_BADGES_CONTRIBUTOR = 'Contributor';
export const GM_INITIAL_BADGES_STAR = 'Rising Star';
export const GM_INITIAL_BADGES_AUTHOR = 'Prolific Author';
export const GM_INITIAL_BADGES_AMBASSADOR = 'Ambassador';

// Viceroy Departments
export const VR_DEPARTMENTS = ["Executive","Housekeeping","Front Office","Accounting", "Operations","F&B","Rooms","Health & Wellness","Finance","HR/L&D","Sales & Marketing","Revenue","Security & Transportation","Engineering & Maintenance", "Hygiene"];

export const GM_INITIAL_BADGES_SET=[
	{
		title: 'Best Trainer Ever for Module 1 Completion',
		tagline: 'Complete all the activities of the module 1' ,
		goal: '1',
		type: 'Module',
		notification: 'You completed all the activities of the module 1! You are now a Best Trainer Ever for Module 1!',
		image: 'https://lykio-dev-data.s3.eu-central-1.amazonaws.com/assets/badges/digi1.png',
		defaultBadge: true
	},
	{
		title: 'Best Trainer Ever for Module 2 Completion',
		tagline: 'Complete all the activities of the module 2',
		goal: '2',
		type: 'Module',
		notification: 'You completed all the activities of the module 2! You are now a Best Trainer Ever for Module 2!',
		image: 'https://lykio-dev-data.s3.eu-central-1.amazonaws.com/assets/badges/digi2.png',
		defaultBadge: true
	},
	{
		title: 'Best Trainer Ever for Module 3 Completion',
		tagline: 'Complete all the activities of the module 3',
		goal: '3',
		type: 'Module',
		notification: 'You completed all the activities of the module 3! You are now a Best Trainer Ever for Module 3!',
		image: 'https://lykio-dev-data.s3.eu-central-1.amazonaws.com/assets/badges/digi3.png',
		defaultBadge: true
	},
	{
		title: 'Best Trainer Ever for Module 4 Completion',
		tagline: 'Complete all the activities of the module 4',
		goal: '4',
		type: 'Module',
		notification: 'You completed all the activities of the module 4! You are now a Best Trainer Ever for Module 4!',
		image: 'https://lykio-dev-data.s3.eu-central-1.amazonaws.com/assets/badges/digi4.png',
		defaultBadge: true
	},
	{
		title: 'Best Trainer Ever for Module 5 Completion',
		tagline: 'Complete all the activities of the module 5',
		goal: '5',
		type: 'Module',
		notification:'You completed all the activities of the module 5! You are now a Best Trainer Ever for Module 5!',
		image: 'https://lykio-dev-data.s3.eu-central-1.amazonaws.com/assets/badges/digi5.png',
		defaultBadge: true
	},
	{
		title: 'Best e-Leader Ever for DigiREACT completion',
		tagline: 'Complete all the activities of all modules',
		goal: '0',
		type: 'All',
		notification:'You completed all the activities of all modules. You have earned the title of Best e-Leader Ever for DigiREACT!',
		image: 'https://lykio-dev-data.s3.eu-central-1.amazonaws.com/assets/badges/digi6.png',
		defaultBadge: true
	}
];

export const TIMEZONES = [
	{name: 'GMT',description:'Greenwich Mean Time', relativeToGMT:'GMT', value: 0},
	{name: 'UTC',description:'Universal Coordinated Time', relativeToGMT:'GMT', value: 0},
	{name: 'ECT',description:'European Central Time', relativeToGMT:'GMT+1:00', value: 1},
	{name: 'EET',description:'Eastern European Time', relativeToGMT:'GMT+2:00', value: 2},
	{name: 'ART',description:'(Arabic) Egypt Standard Time', relativeToGMT:'GMT+2:00', value: 2},
	{name: 'EAT',description:'Eastern African Time', relativeToGMT:'GMT+3:00', value: 3},
	{name: 'MET',description:'Middle East Time', relativeToGMT:'GMT+3:30', value: 3.5},
	{name: 'NET',description:'Near East Time', relativeToGMT:'GMT+4:00', value: 4},
	{name: 'PLT',description:'Pakistan Lahore Time', relativeToGMT:'GMT+5:00', value: 5},
	{name: 'IST',description:'India Standard Time', relativeToGMT:'GMT+5:30', value: 5.5},
	{name: 'BST',description:'Bangladesh Standard Time', relativeToGMT:'GMT+6:00', value: 6},
	{name: 'VST',description:'Vietnam Standard Time', relativeToGMT:'GMT+7:00', value: 7},
	{name: 'CTT',description:'China Taiwan Time', relativeToGMT:'GMT+8:00', value: 8},
	{name: 'JST',description:'Japan Standard Time', relativeToGMT:'GMT+9:00', value: 9},
	{name: 'ACT',description:'Australia Central Time', relativeToGMT:'GMT+10:30', value: 10.5},
	{name: 'AET',description:'Australia Eastern Time', relativeToGMT:'GMT+10:00', value: 10},
	{name: 'SST',description:'Solomon Standard Time', relativeToGMT:'GMT+11:00', value: 11},
	{name: 'NST',description:'New Zealand Standard Time', relativeToGMT:'GMT+12:00', value: 12},
	{name: 'MIT',description:'Midway Islands Time', relativeToGMT:'GMT-11:00', value: -11},
	{name: 'HST',description:'Hawaii Standard Time', relativeToGMT:'GMT-10:00', value: -10},
	{name: 'AST',description:'Alaska Standard Time', relativeToGMT:'GMT-9:00', value: -9},
	{name: 'PST',description:'Pacific Standard Time', relativeToGMT:'GMT-8:00', value: -8},
	{name: 'PNT',description:'Phoenix Standard Time', relativeToGMT:'GMT-7:00', value: -7},
	{name: 'MST',description:'Mountain Standard Time', relativeToGMT:'GMT-7:00', value: -7},
	{name: 'CST',description:'Central Standard Time', relativeToGMT:'GMT-6:00', value: -6},
	{name: 'EST',description:'Eastern Standard Time', relativeToGMT:'GMT-5:00', value: -5},
	{name: 'IET',description:'Indiana Eastern Standard Time', relativeToGMT:'GMT-5:00', value: -5},
	{name: 'PRT',description:'Puerto Rico and US Virgin Islands Time', relativeToGMT:'GMT-4:00', value: -4},
	{name: 'CNT',description:'Canada Newfoundland Time', relativeToGMT:'GMT-3:30', value: -3.5},
	{name: 'AGT',description:'Argentina Standard Time', relativeToGMT:'GMT-3:00', value: -3},
	{name: 'BET',description:'Brazil Eastern Time', relativeToGMT:'GMT-3:00', value: -3},
	{name: 'CAT',description:'Central African Time', relativeToGMT:'GMT-1:00', value: -1}
]

export const AVAILABLE_THEMES = [
    {
	name: "Default",
	properties:{
		primaryColor: "#292a2b",
		primaryColorAccent: "#292a2b",
		secondaryColor: "#dadce1",
		secondaryColorAccent: "#fbfcfd",
		backgroundColor: "#a6a6a6",
		formBackgroundColor: "white",
		activeColor: "#1976d2",
		primaryTextColor: "black",
		reversedTextColor: "#fbfcfd",
		specialTextColor: "black",
		mutedTextColor: "#484a4d"
		}
    },
	{
	name: "ViceroyGold",
	properties:{
		primaryColor: "#84754E",
		primaryColorAccent: "#484a4d",
		secondaryColor: "white",
		secondaryColorAccent: "#fbfcfd",
		backgroundColor: "#a6a6a6",
		formBackgroundColor: "white",
		activeColor: "#1976d2",
		primaryTextColor: "black",
		reversedTextColor: "#fbfcfd",
		specialTextColor: "black",
		mutedTextColor: "#484a4d"
		}
	},
	{
	name: "PearlRiver",
	properties:{
		primaryColor: "#8c8c8c",
		primaryColorAccent: "black",
		secondaryColor: "white",
		secondaryColorAccent: "#fbfcfd",
		backgroundColor: "#e6e6e6",
		formBackgroundColor: "white",
		activeColor: "#1976d2",
		primaryTextColor: "black",
		reversedTextColor: "#fbfcfd",
		specialTextColor: "black",
		mutedTextColor: "#484a4d"
		}
	},
	{
		name: "Paua",
		properties:{
			primaryColor: "#3F1651",
			primaryColorAccent: "#292a2b",
			secondaryColor: "#dadce1",
			secondaryColorAccent: "#fbfcfd",
			backgroundColor: "#a6a6a6",
			formBackgroundColor: "white",
			activeColor: "#1976d2",
			primaryTextColor: "black",
			reversedTextColor: "#fbfcfd",
			specialTextColor: "black",
			mutedTextColor: "#484a4d"
			}
	},
    {
	name: "GhostWhite",
	properties:{
		primaryColor: "#d0d0d0",
		primaryColorAccent: "black",
		secondaryColor: "black",
		secondaryColorAccent: "black",
		backgroundColor: "white",
		formBackgroundColor: "white",
		activeColor: "#1976d2",
		primaryTextColor: "black",
		reversedTextColor: "#fbfcfd",
		specialTextColor: "black",
		mutedTextColor: "#484a4d"
		}
    },
	{
        name: "DigitalOrange",
        properties: {
            primaryColor: "#ed7d31",
            primaryColorAccent: "#292a2b",
            secondaryColor: "#dadce1",
            secondaryColorAccent: "#fbfcfd",
            backgroundColor: "#efeae6",
            formBackgroundColor: "white",
            activeColor: "#1976d2",
            primaryTextColor: "black",
            reversedTextColor: "#fbfcfd",
            specialTextColor: "black",
            mutedTextColor: "#484a4d"
        }
    }
  ];
