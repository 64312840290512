import React, {useState} from 'react';
import t from "../../Utils/Translation";
import './profileAwards.css';
import axios from 'axios'
import {
  AUTHORIZED_URL_PREFIX,
  REGISTER_ACCESS_CODE,
  AxiosConfig,
  NOTIFICATION_SEVERITY_INFO
} from '../../Utils/Constants'
import { ListGroup, Container, Row, Form, FloatingLabel } from 'react-bootstrap';
import friendlyDate from '../../Utils/FriendlyDate';
import LykioButton from "../common/LykioButton";
import Loading from '../common/Loading'
import { notify, pleaseRefreshError } from '../../Utils/Notificator'


const ProfileAccessCodes = (props) => {
	const activeAccessCodes = props.activeAccessCodes;
	const [accessCode, setAccessCode] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const registerAccessCodeHandler = async() => {
		setIsLoading(true)
		const { data } = await axios.post(
		  `${AUTHORIZED_URL_PREFIX}${REGISTER_ACCESS_CODE}`,
		  {accessCode},
		  AxiosConfig(),
		)
		if (data.success) {
		  setAccessCode();
		  notify({
			title: `${t('Your access code has been registered.')}`,
			severity: NOTIFICATION_SEVERITY_INFO,
			creationDate: '',
		  })
		  setIsLoading(false);
		  window.location.reload();
		} else {
		  notify(pleaseRefreshError)
		}
	}

	return <React.Fragment> 
		{isLoading ? <Loading/> : 
		<div>
		<div className="card mb-4 lykiocss-profile-text-color" style={{padding:'1em'}}>
		<h3 className="lykiocss-center">Active Access Codes</h3>
		<ListGroup>
			{activeAccessCodes.map((activeAccessCode => <ListGroup.Item>
				<li>Code: <b>{activeAccessCode.serial}</b> for <b>{activeAccessCode.type}</b>, level: <b>{activeAccessCode.level}</b> activated on {friendlyDate(activeAccessCode.activatedDate)}, total duration: {activeAccessCode.duration} days</li>
				</ListGroup.Item> )) }
		</ListGroup>
		<Form className="lykiocss-form lykiocss-form-border lykiocss-form-size">
		<h5 className="lykiocss-center">{t("Add more products")}</h5>
		<Container className="lykiocss-margin-top-1em">
		  <Row>
			  <Form.Group className="mb-3" controlId="title">
				  <FloatingLabel controlId="floatingtitle" label={t("Serial Number")}>
				  <Form.Control
					required
					type="text"
					maxLength={100}
					placeholder={t("Enter the access code")}
					value={accessCode}
					onChange={(e) => {
						setAccessCode(e.target.value);
					}}
				  />
				  </FloatingLabel>
				</Form.Group>
		  </Row>
		  <LykioButton
			  onClick={()=> registerAccessCodeHandler()}
			  variant="contained"
			  type="submit"
			  className="float-end lykiocss-form-button "
			  
			  >
			  {t("Add")}
		  </LykioButton>
		</Container>
	  </Form>
		</div>
	</div>
	}
	</React.Fragment>
		
	
};

export default ProfileAccessCodes;