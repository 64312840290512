import React from 'react';
import t from "../../Utils/Translation";
import './profileAwards.css';
import Image from 'react-bootstrap/Image';

const ProfileAwards = (props) => {
	const user = props.user;
	console.log('>>>>>user',user);
	const earnedAwards = (user && user.awards) ? user.awards : [] ;
	const awards = props.awards;

	const enlargeAwardImageHandler = (e) => {
		e.target.style.transform = 'scale(1.2)';
		e.target.style.transition = '0.15s ease-in-out';
		e.target.style.filter = 'grayscale(0%)';
		e.target.style.opacity = '1';
		e.target.parentElement.childNodes[1].style.opacity = '0';
	};

	const resetAwardImageHandler = (e) => {
		e.target.style.transform = 'scale(1)';
		e.target.style.filter = 'grayscale(100%)';
		e.target.style.opacity = '0.6';
		e.target.parentElement.childNodes[1].style.opacity = '1';
	};

	return (
		<div>
			<div className="card mb-4 lykiocss-profile-text-color">
				<ul className="card-body lykiocss-profile-awards-container">
					{awards && awards.length > 0 ? (
						awards.map((award, index) => {
							return (
								<React.Fragment>
								{/*(index+1)%3===0 && <div className="lykiocss-profile-awards-break-line" />*/}
								<li className="lykiocss-profile-awards-singe-award" key={index}>
									<div className="lykiocss-profile-awards-image-area">
										<Image
											style={
												!earnedAwards.includes(award._id) ? (
													{ filter: 'grayscale(100%)', opacity: '0.6' }
												) : null
											}
											className="lykiocss-profile-awards-image"
											src={award.image}
											onMouseEnter={!earnedAwards.includes(award._id) ? enlargeAwardImageHandler : null}
											onMouseLeave={!earnedAwards.includes(award._id) ? resetAwardImageHandler : null}
										/>
										{!earnedAwards.includes(award._id) && (
											<div className="lykiocss-profile-awards-lock-div">
												<i className="bi bi-lock-fill lykiocss-profile-awards-lock-icon" />
											</div>
										)}
									</div>
									<div className="lykiocss-profile-awards-text-area">
										<div className="lykiocss-profile-awards-singe-award-title">{award.title}</div>
										<div className="text-muted" >{award.tagline}</div>
									</div>
								</li>
								</React.Fragment>
							);
						})
					) : (
						<div className="lykiocss-center">{t("No awards set for your organization")}</div>
					)}
				</ul>
			</div>
		</div>
	);
};

export default ProfileAwards;