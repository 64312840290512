import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import {
  HOME_URL,
  SINGLE_TENANCY_REGISTER_URL,
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  FRONTEND_LOGIN_URL,
  NOTIFICATION_SEVERITY_SUCCESS,
  NOTIFICATION_SEVERITY_WARNING,
  AxiosConfigNoAuth,
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import "./RegisterScreen.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import Modal from "react-bootstrap/Modal";
import GDPR from "./GDPR";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';

const SingleTenancyRegisterScreen = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [authScreensLogo, setAuthScreensLogo] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(async () => {
    const { data } = await axios.get(
      `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
      AxiosConfigNoAuth()
    );
    data.uberSettings && setAuthScreensLogo(data.uberSettings.authScreensLogo);

    const storageAuthTokenValue = localStorage.getItem("authToken");
    if (storageAuthTokenValue) {
      navigate(FRONTEND_LOGIN_URL);
    }
  }, []);

  const registerHandler = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      notify({
        title: `${t("Password must at least 6 characters long!")}`,
        severity: NOTIFICATION_SEVERITY_WARNING,
        creationDate: "",
      });
    } else {
      if (password !== confirmPassword) {
        setPassword("");
        setConfirmPassword("");
        notify({
          title: `${t("Passwords do not match")}`,
          severity: NOTIFICATION_SEVERITY_WARNING,
          creationDate: "",
        });
      } else {
        try {
          const { data } = await axios.post(
            SINGLE_TENANCY_REGISTER_URL,
            { firstname, lastname, email, password },
            AxiosConfigNoAuth()
          );
          if (data.success) {
            notify({
              title: `${t(
                "You have successfully registered. An activation email was sent to your email."
              )}`,
              severity: NOTIFICATION_SEVERITY_SUCCESS,
              creationDate: "",
            });
          }
          navigate(FRONTEND_LOGIN_URL);
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
            // Display the custom error message from the server
            notify({
              title: error.response.data.message,
              severity: NOTIFICATION_SEVERITY_WARNING,
              creationDate: "",
            });
          } else {
            notify(pleaseRefreshError);
          }
        }
      }
    }
  };

  return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
      <div className="lykiocss-screen-center">
        <Form
          onSubmit={registerHandler}
          className="LykioForm lykiocss-white-background lykiocss-form-border lykiocss-form-size"
        >
          <Link to={HOME_URL}>
            <div className="lykiocss-center">
              <img
                className="lykiocss-small-login-logo"
                src={authScreensLogo ? authScreensLogo : lykio_logo}
                alt=""
              />
            </div>
          </Link>
          <h3 className="register-screen__title">{t("Sign up for Lykio")}</h3>
          <Form.Group className="mb-3" controlId="firstname">
            <Form.Label>{t("First name")}</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t("Enter your first name")}
              value={firstname}
              onChange={(e) => {
                setFirstname(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="lastname">
            <Form.Label>{t("Last name")}</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t("Enter your last name")}
              value={lastname}
              onChange={(e) => {
                setLastname(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>{t("Password")}</Form.Label>
            <div className="position-relative">
              <Form.Control
                required
                type={showPassword ? 'text' : 'password'}
                placeholder={t("Enter password (6 characters or more)")}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePassword}
                  edge="end"
                  className="position-absolute end-0 translate-middle-y"
                  style={{ marginRight: 'auto', zIndex: 1 }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmpassword">
            <Form.Label>{t("Confirm Password")}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t("Confirm your password")}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Button
              variant="primary"
              type="submit"
              className="float-end"
              size="sm"
            >
              {t("Register")}
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default SingleTenancyRegisterScreen;
