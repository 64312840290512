import { useState, useEffect, useContext } from 'react';
import { LykioContext } from '../routing/LykioAuthorizedRoute';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import {
	AUTHORIZED_URL_PREFIX,
	CONTENT_MANAGEMENT_MODERATE_WITHOUTPARAM_URL,
	AxiosConfig
} from '../../Utils/Constants';
import './contentModeration.css';
import t from "../../Utils/Translation";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Loading from '../common/Loading';
import { notify, pleaseRefreshError } from '../../Utils/Notificator';
import UnitsTable from './UnitsTable';
import LykioButton from "./../common/LykioButton";

const ContentModeration = () => {
	const context = useContext(LykioContext);
    const userDetails = context.userDetails;

	const [ isLoading, setIsLoading ] = useState(true);
	const [ units, setUnits ] = useState([]);
	const [ types, setTypes ] = useState([ 'System', 'Stories' ]);
	const { type } = useParams();


	const fetchUnitModerationData = async () => {
		setIsLoading(true);
		try {
			const { data } = await axios.post(
				`${AUTHORIZED_URL_PREFIX}${CONTENT_MANAGEMENT_MODERATE_WITHOUTPARAM_URL}`,
				{ type },
				AxiosConfig()
			);
			setUnits(data.units);
			setIsLoading(false);
			if (!data.success) {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.error(error);
			notify(pleaseRefreshError);
		}
	};

	const toggleScorm = async () => {
		setIsLoading(true);
		try {
			const { data } = await axios.get(
				`${AUTHORIZED_URL_PREFIX}${"/toggleScorm"}`,
				AxiosConfig()
			);
			if (!data.success) {
				notify(pleaseRefreshError);
			}
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			notify(pleaseRefreshError);
		}
	};

	useEffect(
		() => {
			fetchUnitModerationData();
		},
		[ type ]
	);

	return (
		<div className="lykioccs-main-content">
			{isLoading ? (
				<Loading />
			) : (
				<div>
					<div style={{marginLeft:'3px'}} className='lykiocss lykiocss-title-light'>{t('Unit Management')}</div>
					{(userDetails && userDetails.email) && 	<LykioButton onClick={() => toggleScorm()} variant="contained" className="float-end">
			{t('Toggle SCORM')}
		</LykioButton>}
					<DropdownButton title={`${t('Select a type')}`} className="lykioccs-usermanagement-btn" style={{marginLeft:'3px'}}>
						{types.map((sometype, index) => {
							return (
								<Dropdown.Item
									as={Link}
									key={index}
									to={`${CONTENT_MANAGEMENT_MODERATE_WITHOUTPARAM_URL}/${sometype}`}
								>
									{t(`${sometype}`)}
								</Dropdown.Item>
							);
						})}
					</DropdownButton>
					{units.length > 0 ? (
						<div className='lykiocss-material-table'>
							<h5>{type}</h5>
							<UnitsTable units={units} type={type} />
						</div>
					) : (
						<div className="lykiocss-center">
							{t('No entries for')} {type}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ContentModeration;
