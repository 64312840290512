import { useState, useEffect } from "react";
import axios from "axios";
import LykioButton from "../common/LykioButton";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  AUTHORIZED_URL_PREFIX,
  PUBLIC_URL_PREFIX,
  NOTIFICATION_SEVERITY_SUCCESS,
  UBER_ADMIN_SETTINGS_URL,
  UBER_ADMIN_MIGRATEUSERSGROUPS_URL,
  AxiosConfig, AVAILABLE_THEMES,
} from "../../Utils/Constants";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import "./firstAdminScreen.css";
import t from "../../Utils/Translation";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import FloatingLabel from "react-bootstrap/FloatingLabel";

const UberScreen = () => {
  const [key, setKey] = useState('login');

  // UI
  const [authScreensLogo, setAuthScreensLogo] = useState();
  const [lykioBackground, setLykioBackground] = useState();
  const [rootLogo, setRootLogo] = useState();
  const [themes, setThemes] = useState(AVAILABLE_THEMES);
  const [rootTheme, setRootTheme] = useState(AVAILABLE_THEMES[0].name);
  const [darkRootHeaders, setDarkRootHeaders] = useState(false);
  const [forceRootTheme, setForceRootTheme] = useState(false);
  const [supportEmail, setSupportEmail] = useState("");

  // Login Form
  const [showRegisterOrg, setShowRegisterOrg] = useState(false);
  const [showRegisterToLykio, setShowRegisterToLykio] = useState(false);
  const [showRegisterWithMagicCode, setShowRegisterWithMagicCode] =
    useState(false);
  const [showRegisterWithAccessCode, setShowRegisterWithAccessCode] =
    useState(false);
  const [showForgotPassMessage, setShowForgotPassMessage] = useState(true);
  const [forgotPassMessage, setForgotPassMessage] = useState("");
  const [hideLoginFields, setHideLoginFields] = useState(false);
  const [enableAzureADlogin, setEnableAzureADLogin] = useState(false);
  const [azureADClientID, setAzureADClientID] = useState('fa4cd176-c225-4766-a67a-b8460e63b709');
  const [azureADAuthority, setAzureADAuthority] = useState('https://login.microsoftonline.com/common');
  const [azureADRedirectURI, setAzureADRedirectURI] = useState('http://localhost:3000/login');
  const [azureADClientSecret, setAzureADClientSecret] = useState('secret');
  // Languages
  const [languages, setLanguages] = useState([]);
  const [languageCode, setLanguageCode] = useState();
  const [languageName, setLanguageName] = useState();

  // Download pages
  const [showDownloadsPage, setShowDownloadsPage] = useState(false);
  const [windowsDownloadLink, setWindowsDownloadLink] = useState("#");
  const [macDownloadLink, setMacDownloadLink] = useState("#");
  const [androidDownloadLink, setAndroidDownloadLink] = useState("#");
  const [iosDownloadLink, setIosDownloadLink] = useState("#");
  const [clientSafeVersion, setClientSafeVersion] = useState("");

  // Features
  const [enableSchools, setEnableSchools] = useState(false);
  const [showBillingOptions, setShowBillingOptions] = useState(false);
  const [showCreateAccessCode, setShowCreateAccessCode] = useState(false);
  const [showResetLeaderboards, setShowResetLeaderboards] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showStories, setShowStories] = useState(false);
  const [showLeaderboards, setShowLeaderboards] = useState(false);
  const [showContentManagement, setShowContentManagement] = useState(false);
  const [showFeed, setShowFeed] = useState(false);
  const [showCollectionsManagement, setShowCollectionsManagement] = useState(false);
  const [showCollections, setShowCollections] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showMessenger, setShowMessenger] = useState(false);
  const [showMessengerFeatures, setShowMessengerFeatures] = useState(false);
  const [showGuides, setShowGuides] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showAwardsManagement, setShowAwardsManagement] = useState(false);
  const [showCustomizeLykio, setShowCustomizeLykio] = useState(false);
  const [showUserManagement, setShowUserManagement] = useState(false);
  const [showUberUserManagement, setShowUberUserManagement] = useState(false);
  const [showCreateContent, setShowCreateContent] = useState(false);
  const [showProfileProgress,setShowProfileProgress] = useState(false);
  const [forceRootContentToSubTenants, setForceRootContentToSubTenants] = useState(false);
  const [rootInitiative, setRootInitiative] = useState("Lykio");

  // Schools Views
  const [showSchoolsReporting, setShowSchoolsReporting] = useState(false);
  const [showSchoolsUserManagement, setShowSchoolsUserManagement] = useState(false);
  const [showConnections, setShowConnections] = useState(false);
  const [showInnerGamesDownloadPage, setShowInnerGamesDownloadPage] = useState(false);

  // Backup Settings
  const [enableBackup, setEnableBackup] = useState(false);
  const [dailyBackup, setDailyBackup] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const migrateUsersToMultipleGroups = async()=>{
    try{
      console.log('Migrating users to multiple groups');
      const { data } = await axios.post(
        `${AUTHORIZED_URL_PREFIX}${UBER_ADMIN_MIGRATEUSERSGROUPS_URL}`, {},
        AxiosConfig()
      );
      if (data.success === true) {
        notify({
          title: `${t(
            "You have successfully migated your users to the new groups array."
          )}`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        });
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      notify(pleaseRefreshError);
    }
  }

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
        AxiosConfig()
      );
      console.log(data);
      data.uberSettings && setShowRegisterOrg(data.uberSettings.showRegisterOrg);
      data.uberSettings &&
        setShowRegisterToLykio(data.uberSettings.showRegisterToLykio);
      data.uberSettings &&
        setShowRegisterWithMagicCode(data.uberSettings.showRegisterWithMagicCode);
      data.uberSettings &&
        setShowRegisterWithAccessCode(
          data.uberSettings.showRegisterWithAccessCode
        );

      data.uberSettings &&
      setEnableAzureADLogin(
        data.uberSettings.enableAzureADlogin
      );

      data.uberSettings &&
      setAzureADClientID(
        data.uberSettings.azureADClientID
      );

      data.uberSettings &&
      setAzureADAuthority(
        data.uberSettings.azureADAuthority
      );

      data.uberSettings &&
      setAzureADRedirectURI(
        data.uberSettings.azureADRedirectURI
      );

      data.uberSettings &&
      setAzureADClientSecret(
        data.uberSettings.azureADClientSecret
      );

      data.uberSettings &&
        setShowBillingOptions(data.uberSettings.showBillingOptions);
      data.uberSettings &&
        setShowCreateAccessCode(data.uberSettings.showCreateAccessCode);
      data.uberSettings &&
        setShowForgotPassMessage(data.uberSettings.showForgotPassMessage);
      data.uberSettings &&
        setForgotPassMessage(data.uberSettings.forgotPassMessage);
      data.uberSettings && setLanguages(data.uberSettings.languages);
      data.uberSettings && setAuthScreensLogo(data.uberSettings.authScreensLogo);
      data.uberSettings && setLykioBackground(data.uberSettings.lykioBackground);
      data.uberSettings &&
      setShowResetLeaderboards(data.uberSettings.showResetLeaderboards);
      data.uberSettings &&
      setShowDownloadsPage(data.uberSettings.showDownloadsPage);
      data.uberSettings &&
      setWindowsDownloadLink(data.uberSettings.windowsDownloadLink);
      data.uberSettings &&
      setMacDownloadLink(data.uberSettings.macDownloadLink);
      data.uberSettings &&
      setAndroidDownloadLink(data.uberSettings.androidDownloadLink);
      data.uberSettings &&
      setIosDownloadLink(data.uberSettings.iosDownloadLink);
      data.uberSettings &&
      setHideLoginFields(data.uberSettings.hideLoginFields);
      data.uberSettings &&
      setClientSafeVersion(data.uberSettings.clientSafeVersion);
      
      data.uberSettings &&
      setEnableBackup(data.uberSettings.enableBackup);
      data.uberSettings &&
      setDailyBackup(data.uberSettings.dailyBackup);

      data.uberSettings &&
      setEnableSchools(data.uberSettings.enableSchools);
      data.uberSettings &&
      setShowCategories(data.uberSettings.showCategories);
      data.uberSettings &&
      setShowStories(data.uberSettings.showStories);
      data.uberSettings &&
      setShowLeaderboards(data.uberSettings.showLeaderboards);
      data.uberSettings &&
      setShowContentManagement(data.uberSettings.showContentManagement);
      data.uberSettings &&
      setShowFeed(data.uberSettings.showFeed);
      data.uberSettings &&
      setShowCollectionsManagement(data.uberSettings.showCollectionsManagement);
      
      data.uberSettings &&
      setShowCollections(data.uberSettings.showCollections);
      data.uberSettings &&
      setShowSearch(data.uberSettings.showSearch);
      data.uberSettings &&
      setShowNotifications(data.uberSettings.showNotifications);
      data.uberSettings &&
      setShowMessenger(data.uberSettings.showMessenger);
      data.uberSettings &&
      setShowMessengerFeatures(data.uberSettings.showMessengerFeatures);
      data.uberSettings &&
      setShowGuides(data.uberSettings.showGuides);
      data.uberSettings &&
      setShowReports(data.uberSettings.showReports);
      
      data.uberSettings &&
      setShowAwardsManagement(data.uberSettings.showAwardsManagement);
      data.uberSettings &&
      setShowCustomizeLykio(data.uberSettings.showCustomizeLykio);
      data.uberSettings &&
      setShowUserManagement(data.uberSettings.showUserManagement);
      data.uberSettings &&
      setShowUberUserManagement(data.uberSettings.showUberUserManagement);
      data.uberSettings &&
      setShowCreateContent(data.uberSettings.showCreateContent);
      data.uberSettings &&
      setShowCreateContent(data.uberSettings.showCreateContent);
      data.uberSettings &&
      setShowProfileProgress(data.uberSettings.showProfileProgress);
      data.uberSettings &&
      setShowSchoolsUserManagement(data.uberSettings.showSchoolsUserManagement);
      data.uberSettings &&
      setShowConnections(data.uberSettings.showConnections);
      data.uberSettings &&
      setShowSchoolsReporting(data.uberSettings.showSchoolsReporting);
      data.uberSettings &&
      setShowInnerGamesDownloadPage(data.uberSettings.showInnerGamesDownloadPage);
      data.uberSettings && setForceRootContentToSubTenants(data.uberSettings.forceRootContentToSubTenants);
      
      data.uberSettings && setForceRootTheme(data.uberSettings.forceRootTheme);
      data.uberSettings && setRootLogo(data.uberSettings.rootLogo);
      data.uberSettings && setDarkRootHeaders(data.uberSettings.darkRootHeaders);
      data.uberSettings && setRootTheme(data.uberSettings.rootTheme);
      data.uberSettings && setRootInitiative(data.uberSettings.rootInitiative);

      data.uberSettings && setSupportEmail(data.uberSettings.supportEmail);

    }
    fetchData();
  }, []);

  const uberSettingsHandler = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      // formData.append('logo', logo);
      formData.append("authScreensLogo", authScreensLogo);
      formData.append("lykioBackground", lykioBackground);
      formData.append("showRegisterOrg", showRegisterOrg);
      formData.append("showRegisterToLykio", showRegisterToLykio);
      formData.append("showRegisterWithMagicCode", showRegisterWithMagicCode);
      formData.append("showRegisterWithAccessCode", showRegisterWithAccessCode);

      console.log(enableAzureADlogin,azureADClientID,azureADAuthority,azureADRedirectURI);
      formData.append("enableAzureADlogin", enableAzureADlogin);
      formData.append("azureADClientID", azureADClientID);
      formData.append("azureADAuthority", azureADAuthority);
      formData.append("azureADRedirectURI", azureADRedirectURI);
      formData.append("azureADClientSecret", azureADClientSecret);

      formData.append("showBillingOptions", showBillingOptions);
      formData.append("showCreateAccessCode", showCreateAccessCode);
      formData.append("showForgotPassMessage", showForgotPassMessage);
      formData.append("forgotPassMessage", forgotPassMessage);
      formData.append("showResetLeaderboards", showResetLeaderboards);
      formData.append("languageCode", languageCode);
      formData.append("languageName", languageName);
      formData.append("showDownloadsPage", showDownloadsPage);
      formData.append("windowsDownloadLink", windowsDownloadLink);
      formData.append("macDownloadLink", macDownloadLink);
      formData.append("androidDownloadLink", androidDownloadLink);
      formData.append("iosDownloadLink", iosDownloadLink);
      formData.append("clientSafeVersion", clientSafeVersion);
      formData.append("hideLoginFields", hideLoginFields);
      formData.append("enableBackup", enableBackup);
      formData.append("dailyBackup", dailyBackup);

      formData.append("enableSchools", enableSchools);
      formData.append("showCategories", showCategories);
      formData.append("showStories", showStories);
      formData.append("showLeaderboards", showLeaderboards);
      formData.append("showContentManagement", showContentManagement);
      formData.append("showFeed", showFeed);
      formData.append("showCollectionsManagement", showCollectionsManagement);
      formData.append("showCollections", showCollections);
      formData.append("showSearch", showSearch);
      formData.append("showNotifications", showNotifications);
      formData.append("showMessenger", showMessenger);
      formData.append("showMessengerFeatures", showMessengerFeatures);
      formData.append("showGuides", showGuides);
      formData.append("showReports", showReports);
      formData.append("showAwardsManagement", showAwardsManagement);
      formData.append("showCustomizeLykio", showCustomizeLykio);
      formData.append("showUserManagement", showUserManagement);
      formData.append("showUberUserManagement", showUberUserManagement);
      formData.append("showCreateContent", showCreateContent);
      formData.append("showProfileProgress", showProfileProgress);
      formData.append("showSchoolsReporting", showSchoolsReporting);
      formData.append("showSchoolsUserManagement", showSchoolsUserManagement);
      formData.append("showConnections", showConnections);
      formData.append("showInnerGamesDownloadPage", showInnerGamesDownloadPage);
      formData.append("forceRootContentToSubTenants", forceRootContentToSubTenants);
      formData.append("forceRootTheme", forceRootTheme);
      formData.append("darkRootHeaders", darkRootHeaders);
      formData.append("rootTheme", rootTheme);
      formData.append("rootLogo", rootLogo);
      formData.append("rootInitiative",rootInitiative);
      formData.append("supportEmail",supportEmail);

      const { data } = await axios.post(
        `${AUTHORIZED_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
        formData,
        AxiosConfig("multipart/form-data")
      );
      if (data.success === true) {
        notify({
          title: `${t(
            "You have successfully updated your Uber Lykio Settings."
          )}`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        });
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      notify(pleaseRefreshError);
    }
  };


  return (
    <div className="lykioccs-main-content">
      <div className="lykiocss-margin-top-1em lykiocss-margin-bottom-half-em">
        <Form
          onSubmit={uberSettingsHandler}
          className="lykiocss-form lykiocss-form-border lykiocss-form-size lykiocss-form-bottom-padding"
        >
          <h3 className="lykiocss-center">{t("Uber Admin Management")}</h3>
          <Container>
            <Row>
            <Tabs defaultActiveKey="profile" 
            id="uncontrolled-tab-example" 
            activeKey={key}
            onSelect={(k) => setKey(k)} 
            className="mb-3">
            <Tab eventKey="login" title="Login Screens">
            <Container>
              <Row>
                <Col>
                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowRegisterOrg(!showRegisterOrg);
                    }}
                    checked={showRegisterOrg}
                    type="switch"
                    id="showRegisterOrg"
                    label={t("Show Register Organisation")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowRegisterToLykio(!showRegisterToLykio);
                    }}
                    checked={showRegisterToLykio}
                    type="switch"
                    id="showRegisterToLykio"
                    label={t("Show Register To Lykio")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowRegisterWithMagicCode(!showRegisterWithMagicCode);
                    }}
                    checked={showRegisterWithMagicCode}
                    type="switch"
                    id="showRegisterWithMagicCode"
                    label={t("Show Register With Magic Code")}
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowRegisterWithAccessCode(
                        !showRegisterWithAccessCode
                      );
                    }}
                    checked={showRegisterWithAccessCode}
                    type="switch"
                    id="showRegisterWithAccessCode"
                    label={t("Show Register With Access Code")}
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setHideLoginFields(!hideLoginFields);
                    }}
                    checked={hideLoginFields}
                    type="switch"
                    id="hideLoginFields"
                    label={t("Hide Login Fields")}
                  />
                </Form.Group>
                <br />
                </Col>
                <Col>
                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setEnableAzureADLogin(!enableAzureADlogin);
                    }}
                    checked={enableAzureADlogin}
                    type="switch"
                    id="enableAzureADlogin"
                    label={t("Enable Azure AD Login")}
                  />
                </Form.Group>
                <br />
                <Form.Group className="mb-3" controlId="ClientId">
                  <Form.Label>{t("Azure AD ClientId")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set Azure AD ClientId"
                    )}
                    value={azureADClientID}
                    onChange={(e) => {
                      setAzureADClientID(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="authority">
                  <Form.Label>{t("Microsoft Authority")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set Microsoft Authority"
                    )}
                    value={azureADAuthority}
                    onChange={(e) => {
                      setAzureADAuthority(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="ClientId">
                  <Form.Label>{t("Client Redirect URI")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set Client Redirect URI"
                    )}
                    value={azureADRedirectURI}
                    onChange={(e) => {
                      setAzureADRedirectURI(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="ClientSecret">
                  <Form.Label>{t("Client Secret")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set Client Secret"
                    )}
                    value={azureADClientSecret}
                    onChange={(e) => {
                      setAzureADClientSecret(e.target.value);
                    }}
                  />
                </Form.Group>
                </Col>
              </Row>
            </Container>



            </Tab>
            <Tab eventKey="features" title="Features">
              <Container>
              <Row>
                <Col>
                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowCategories(!showCategories);
                    }}
                    checked={showCategories}
                    type="switch"
                    id="showCategories"
                    label={t("Show traditional categories version")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowStories(!showStories);
                    }}
                    checked={showStories}
                    type="switch"
                    id="showStories"
                    label={t("Show Stories")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowLeaderboards(!showLeaderboards);
                    }}
                    checked={showLeaderboards}
                    type="switch"
                    id="showLeaderboards"
                    label={t("Show Leaderboards")}
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowFeed(!showFeed);
                    }}
                    checked={showFeed}
                    type="switch"
                    id="showFeed"
                    label={t("Show Feed")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowCollectionsManagement(!showCollectionsManagement);
                    }}
                    checked={showCollectionsManagement}
                    type="switch"
                    id="showCollectionsManagement"
                    label={t("Show Collections Management")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowCollections(!showCollections);
                    }}
                    checked={showCollections}
                    type="switch"
                    id="showCollections"
                    label={t("Show Collections")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowSearch(!showSearch);
                    }}
                    checked={showSearch}
                    type="switch"
                    id="showSearch"
                    label={t("Show Search")}
                  />
                </Form.Group>
                <br />
                <Form.Group>
                    <Form.Check
                      onChange={() => {
                        setShowBillingOptions(!showBillingOptions);
                      }}
                      checked={showBillingOptions}
                      type="switch"
                      id="showBillingOptions"
                      label={t("Show Billing Options")}
                    />
                  </Form.Group>
                  <br />
                <Form.Group>
                <Form.Check
                  onChange={() => {
                    setShowResetLeaderboards(!showResetLeaderboards);
                  }}
                  checked={showResetLeaderboards}
                  type="switch"
                  id="showResetLeaderboards"
                  label={t("Show Reset Leaderboards")}
                />
                </Form.Group>
                <br />
                </Col>
                <Col>
                
                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowContentManagement(!showContentManagement);
                    }}
                    checked={showContentManagement}
                    type="switch"
                    id="showContentManagement"
                    label={t("Show Content Management")}
                  />
                </Form.Group>
                <br />

                
                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowNotifications(!showNotifications);
                    }}
                    checked={showNotifications}
                    type="switch"
                    id="showNotifications"
                    label={t("Show Notifications")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowMessenger(!showMessenger);
                    }}
                    checked={showMessenger}
                    type="switch"
                    id="showMessenger"
                    label={t("Show Messenger")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowMessengerFeatures(!showMessengerFeatures);
                    }}
                    checked={showMessengerFeatures}
                    type="switch"
                    id="showMessengerFeatures"
                    label={t("Show Messenger Advanced Features")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowGuides(!showGuides);
                    }}
                    checked={showGuides}
                    type="switch"
                    id="showGuides"
                    label={t("Show Guides")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowReports(!showReports);
                    }}
                    checked={showReports}
                    type="switch"
                    id="showReports"
                    label={t("Show Reports")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowAwardsManagement(!showAwardsManagement);
                    }}
                    checked={showAwardsManagement}
                    type="switch"
                    id="showAwardsManagement"
                    label={t("Show Awards Management")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowCustomizeLykio(!showCustomizeLykio);
                    }}
                    checked={showCustomizeLykio}
                    type="switch"
                    id="showCustomizeLykio"
                    label={t("Show Customize Lykio")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowUserManagement(!showUserManagement);
                    }}
                    checked={showUserManagement}
                    type="switch"
                    id="showUserManagement"
                    label={t("Show User Management")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowUberUserManagement(!showUberUserManagement);
                    }}
                    checked={showUberUserManagement}
                    type="switch"
                    id="showUberUserManagement"
                    label={t("Show Uber User Management")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowCreateContent(!showCreateContent);
                    }}
                    checked={showCreateContent}
                    type="switch"
                    id="showCreateContent"
                    label={t("Show Create Content")}
                  />
                </Form.Group>
                <br />                

                </Col>
                <Col>

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowCreateAccessCode(!showCreateAccessCode);
                    }}
                    checked={showCreateAccessCode}
                    type="switch"
                    id="showCreateAccessCode"
                    label={t("Show Create Access Code")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setForceRootContentToSubTenants(!forceRootContentToSubTenants);
                    }}
                    checked={forceRootContentToSubTenants}
                    type="switch"
                    id="forceRootContentToSubTenants"
                    label={t("Force Root Content To Subtenants")}
                  />
                </Form.Group>
                <br />
                
                </Col>
              </Row>
              </Container>
            </Tab>
            <Tab eventKey="maintenance" title="Maintenance">
            <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setEnableBackup(!enableBackup);
                    }}
                    checked={enableBackup}
                    type="switch"
                    id="enableBackup"
                    label={t("Enable database backups to S3")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setDailyBackup(!dailyBackup);
                    }}
                    checked={dailyBackup}
                    type="switch"
                    id="dailyBackup"
                    label={t("Perform backups daily (otherwise they will be performed monthly)")}
                  />
                </Form.Group>
                <br />


                <LykioButton variant="primary" onClick={handleShow}>
                      Migrate Users To Multiple Groups
                </LykioButton>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>User Group Migration</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>You are attempting to migrate to the new users group model from the older one that only allowed for one user group per user.</p>
                    <p>Given the fact that the migration might break your data, a db backup will be attempted before the migration changes take place. If the system cannot perform the backup, the migration will not be attempted.</p>
                    <LykioButton onClick={()=>{migrateUsersToMultipleGroups();}}>I am sure. Migrate Users To Multiple Groups</LykioButton>
                  </Modal.Body>
                  <Modal.Footer>
                    <LykioButton variant="secondary" onClick={handleClose}>
                      Close
                    </LykioButton>
                  </Modal.Footer>
                </Modal>

            </Tab>
            <Tab eventKey="ui" title="User Interface">
                <Form.Group controlId="formLogo" className="mb-3">
                  <Form.Label>{t("Login Screen Logo")}</Form.Label>
                  <Form.Control
                    filename={authScreensLogo}
                    accept="image/*"
                    type="file"
                    onChange={(e) => setAuthScreensLogo(e.target.files[0])}
                  />
                </Form.Group>

                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>{t("Login Screen Background")}</Form.Label>
                  <Form.Control
                    filename={lykioBackground}
                    accept="image/*"
                    type="file"
                    onChange={(e) => setLykioBackground(e.target.files[0])}
                  />
                </Form.Group>
              <Form.Label>{t("My Company Logo")}</Form.Label>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                    filename={rootLogo}
                    accept="image/*"
                    type="file"
                    onChange={(e) => setRootLogo(e.target.files[0])}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="group">
                <FloatingLabel controlId="floatingtheme" label={t(`My Theme`)}>
                  <Form.Select onChange={(e) => {
                    setRootTheme(e.target.value);
                  }}>
                    {themes.map((singleTheme, i) => (
                        <option
                            selected={singleTheme.name === rootTheme}
                            value={singleTheme.name}
                        >
                          {singleTheme.name}
                        </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              
              <Form.Group>
                <Form.Check
                    onChange={() => {
                      setDarkRootHeaders(!darkRootHeaders);
                    }}
                    checked={darkRootHeaders}
                    type="switch"
                    id="darkRootHeaders"
                    label={t("Force dark headers for subtenants")}
                />
              </Form.Group>
              <br/>
              <Form.Group className="mb-3" controlId="rootInitiative">
                  <Form.Label>{t("Set Custom Initiative For All Tenants")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set Custom Initiative For All Tenants"
                    )}
                    value={rootInitiative}
                    onChange={(e) => {
                      setRootInitiative(e.target.value);
                    }}
                  />
              </Form.Group>

              <br/>
              <Form.Group className="mb-3" controlId="supportEmail">
                  <Form.Label>{t("Set custom support email for all tenants")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set custom support email for all tenants"
                    )}
                    value={supportEmail}
                    onChange={(e) => {
                      setSupportEmail(e.target.value);
                    }}
                  />
              </Form.Group>

              <Form.Group>
                <Form.Check
                    onChange={() => {
                      setForceRootTheme(!forceRootTheme);
                    }}
                    checked={forceRootTheme}
                    type="switch"
                    id="showForceRootTheme"
                    label={t("Force Root Theme")}
                />
              </Form.Group>

              <br/>
              <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowForgotPassMessage(
                        !showForgotPassMessage
                      );
                    }}
                    checked={showForgotPassMessage}
                    type="switch"
                    id="showForgotPassMessage"
                    label={t("Show Custom Message For Forgot Pass")}
                  />
              </Form.Group>
              <br />

                <Form.Group className="mb-3" controlId="forgotPassMsg">
                  <Form.Label>{t("Custom Message For Forgot Pass")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set Custom Message For Forgot Pass"
                    )}
                    value={forgotPassMessage}
                    onChange={(e) => {
                      setForgotPassMessage(e.target.value);
                    }}
                  />
                </Form.Group>
                <br/>
                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowProfileProgress(!showProfileProgress);
                    }}
                    checked={showProfileProgress}
                    type="switch"
                    id="showProfileProgress"
                    label={t("Show Profile Progress")}
                  />
                </Form.Group>
                <br />
            </Tab>
            <Tab eventKey="downloads" title="Downloads">
            <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowDownloadsPage(!showDownloadsPage);
                    }}
                    checked={showDownloadsPage}
                    type="switch"
                    id="showDownloadsPage"
                    label={t("Show Downloads Page")}
                  />
                </Form.Group>
                <br />

                <Form.Group>
                  <Form.Check
                    onChange={() => {
                      setShowInnerGamesDownloadPage(!showInnerGamesDownloadPage);
                    }}
                    checked={showInnerGamesDownloadPage}
                    type="switch"
                    id="showInnerGamesDownloadPage"
                    label={t("Show Schools Inner Games Download Page")}
                  />
                </Form.Group>
                <br />

                <Form.Group className="mb-3" controlId="windowndownload">
                  <Form.Label>{t("Windows download link")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set windows download link"
                    )}
                    value={windowsDownloadLink}
                    onChange={(e) => {
                      setWindowsDownloadLink(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="macdownload">
                  <Form.Label>{t("Mac download link")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set mac download link"
                    )}
                    value={macDownloadLink}
                    onChange={(e) => {
                      setMacDownloadLink(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="androiddownload">
                  <Form.Label>{t("Android download link")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set android download link"
                    )}
                    value={androidDownloadLink}
                    onChange={(e) => {
                      setAndroidDownloadLink(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="iosdownload">
                  <Form.Label>{t("iOS download link")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Set ios download link"
                    )}
                    value={iosDownloadLink}
                    onChange={(e) => {
                      setIosDownloadLink(e.target.value);
                    }}
                  />
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="clientSafeVersion">
                  <Form.Label>{t("Client Safe Version")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Client Safe Version"
                    )}
                    value={clientSafeVersion}
                    onChange={(e) => {
                      setClientSafeVersion(e.target.value);
                    }}
                  />
                </Form.Group>
                <br/>

            </Tab>
            <Tab eventKey="languages" title="Languages">
                <Form.Label>{t("Available Languages")}</Form.Label>
                <br />
                <Form.Select>
                  {languages.map((language) => (
                    <option>{t(language.name)}</option>
                  ))}
                </Form.Select>

                <br />
                <Form.Group className="mb-3" controlId="languageCode">
                  <Form.Label>{t("New Language Code")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("Enter i18n value (en, el, es etc.)")}
                    value={languageCode}
                    onChange={(e) => {
                      setLanguageCode(e.target.value);
                    }}
                  />
                </Form.Group>
                <br />
                <Form.Group className="mb-3" controlId="languageName">
                  <Form.Label>{t("New Language Name")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      "Enter language name (English, Greek, Spanish etc.)"
                    )}
                    value={languageName}
                    onChange={(e) => {
                      setLanguageName(e.target.value);
                    }}
                  />
                </Form.Group>
            </Tab>
            </Tabs>
            </Row>
          </Container>

          <LykioButton
            variant="contained"
            type="text"
            className="float-end lykiocss-form-button"
          >
            {t("Update Settings")}
          </LykioButton>
        </Form>
      </div>
    </div>
  );
};

export default UberScreen;
