import { useState, useEffect, useContext } from "react";
import { LykioContext } from "../routing/LykioAuthorizedRoute";
import axios from "axios";
import t from "../../Utils/Translation";
import LykioButton from "../common/LykioButton";
import Form from "react-bootstrap/Form";
import {
  AUTHORIZED_URL_PREFIX,
  CUSTOMIZE_LYKIO_URL,
  NOTIFICATION_SEVERITY_SUCCESS,
  TENANT_SETTINGS,
  GM_INITIAL_BADGES_SET,
  AVAILABLE_THEMES,
  AxiosConfig,
} from "../../Utils/Constants";
import { useNavigate } from "react-router";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";
import ProfileAwards from './../profile/ProfileAwards';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from '../common/Loading';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Modal from 'react-bootstrap/Modal'

const CustomizeLykio = () => {
  const context = useContext(LykioContext);
  const tenantProperties = context.tenantProperties;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ isLoading, setIsLoading ] = useState(true);
  const [logo, setLogo] = useState();
  const [background, setBackground] = useState();
  const [themeName, setThemeName] = useState(
    tenantProperties ? tenantProperties.themeName : "Default"
  );
  const [primaryColor, setPrimaryColor] = useState(
    tenantProperties ? tenantProperties.primaryColor : "#484a4d"
  );

  const [primaryColorAccent, setPrimaryColorAccent] = useState(
    tenantProperties ? tenantProperties.primaryColorAccent : "#484a4d"
  );

  const [secondaryColor, setSecondaryColor] = useState(
    tenantProperties ? tenantProperties.secondaryColor : "white"
  );

  const [secondaryColorAccent, setSecondaryColorAccent] = useState(
    tenantProperties ? tenantProperties.secondaryColorAccent : "#fbfcfd"
  );

  const [backgroundColor, setBackgroundColor] = useState(
    tenantProperties ? tenantProperties.backgroundColor : "white"
  );

  const [formBackgroundColor, setFormBackgroundColor] = useState(
    tenantProperties ? tenantProperties.formBackgroundColor : "white"
  );

  const [activeColor, setActiveColor] = useState(
    tenantProperties ? tenantProperties.activeColor : "#1976d2"
  );

  const [primaryTextColor, setPrimaryTextColor] = useState(
    tenantProperties ? tenantProperties.primaryTextColor : "black"
  );

  const [reversedTextColor, setReversedTextColor] = useState(
    tenantProperties ? tenantProperties.reversedTextColor : "#fbfcfd"
  );

  const [specialTextColor, setSpecialTextColor] = useState(
    tenantProperties ? tenantProperties.specialTextColor : "black"
  );

  const [mutedTextColor, setMutedTextColor] = useState(
    tenantProperties ? tenantProperties.mutedTextColor : "#484a4d"
  );
  const [enableDefaultAwards, setEnableDefaultAwards] = useState(
    tenantProperties && tenantProperties.enableDefaultAwards
  );
  const [enableUsersCanPickTheme, setEnableUsersCanPickTheme] = useState(
    tenantProperties && tenantProperties.enableUsersCanPickTheme
  );
  const [enableUploadForOtherUsers, setEnableUploadForOtherUsers] = useState(
    tenantProperties && tenantProperties.enableUploadForOtherUsers
  );
  const [initiative, setInitiative] = useState(
    tenantProperties && tenantProperties.initiative
  );
  const [welcomeMessage, setWelcomeMessage] = useState(
    tenantProperties && tenantProperties.welcomeMessage
  );
  
  const [groupName, setGroupName] = useState(
    tenantProperties && tenantProperties.groupName
  );

  const [storiesName, setStoriesName] = useState(
    tenantProperties && tenantProperties.storiesName
  );

  const [storiesTagLine, setStoriesTagLine] = useState(
    tenantProperties && tenantProperties.storiesTagLine
  );
  
  const [categoriesName, setCategoriesName] = useState(
    tenantProperties && tenantProperties.categoriesName
  );

  const [rootCategoriesName, setRootCategoriesName] = useState(
    tenantProperties && tenantProperties.rootCategoriesName
  );

  const [showUserGuidesInMainNav, setShowUserGuidesInMainNav] = useState(
    tenantProperties && tenantProperties.showUserGuidesInMainNav
  );

  const [showLocalLeaderboards, setShowLocalLeaderboards] = useState(
    tenantProperties && tenantProperties.showLocalLeaderboards
  );
  const [showGroupLeaderboards, setShowGroupLeaderboards] = useState(
    tenantProperties && tenantProperties.showGroupLeaderboards
  );
  const [showGroupsInLeaderboards, setShowGroupsInLeaderboards] = useState(
    tenantProperties && tenantProperties.showGroupsInLeaderboards
  );
  const [showIdeology, setShowIdeology] = useState(
    tenantProperties && tenantProperties.showIdeology
  );

  const [ideologyS3Url, setIdeologyS3Url] = useState(
    tenantProperties && tenantProperties.ideologyS3Url
  );

  const [showGuides, setShowGuides] = useState(
    tenantProperties && tenantProperties.showGuides
  );

  const [guidesS3Url, setGuidesS3Url] = useState(
    tenantProperties && tenantProperties.guidesS3Url
  );

  const [showManagerInduction, setShowManagerInduction] = useState(
    tenantProperties && tenantProperties.showManagerInduction
  );

  const [managerInductionS3Url, setManagerInductionS3Url] = useState(
    tenantProperties && tenantProperties.managerInductionS3Url
  );

  const [showMessages, setShowMessages] = useState(
    tenantProperties && tenantProperties.showMessages
  );

  const [showCategoryStats, setShowCategoryStats] = useState(
    tenantProperties && tenantProperties.showCategoryStats
  );

  const [showBackgroundImages, setShowBackgroundImages] = useState(
    tenantProperties && tenantProperties.showBackgroundImages
  );

  
  const [makeHeadersDark, setMakeHeadersDark] = useState(
    tenantProperties && tenantProperties.makeHeadersDark
  );

  const [themes, setThemes] = useState(AVAILABLE_THEMES);

  useEffect(() => {
	const fetchTenantSettingsData = async () => {
		try {
			const { data } = await axios.get(`${AUTHORIZED_URL_PREFIX}${TENANT_SETTINGS}`, AxiosConfig());
			setThemeName(data.tenantProperties.themeName)
			setPrimaryColor(data.tenantProperties.primaryColor)
			setSecondaryColor(data.tenantProperties.secondaryColor)
			setEnableUsersCanPickTheme(data.tenantProperties.enableUsersCanPickTheme)
			setEnableUploadForOtherUsers(data.tenantProperties.enableUploadForOtherUsers)
			setEnableDefaultAwards(data.tenantProperties.enableDefaultAwards)
			setStoriesName(data.tenantProperties.storiesName)
			setGroupName(data.tenantProperties.groupName)
			setInitiative(data.tenantProperties.initiative)
			setWelcomeMessage(data.tenantProperties.welcomeMessage)
			setStoriesTagLine(data.tenantProperties.storiesTagLine)
			setCategoriesName(data.tenantProperties.categoriesName)
			setRootCategoriesName(data.tenantProperties.rootCategoriesName)
			setShowUserGuidesInMainNav(data.tenantProperties.showUserGuidesInMainNav)
			setShowLocalLeaderboards(data.tenantProperties.showLocalLeaderboards)
			setShowGroupLeaderboards(data.tenantProperties.showGroupLeaderboards)
			setShowGroupsInLeaderboards(data.tenantProperties.showGroupsInLeaderboards)
			setShowIdeology(data.tenantProperties.showIdeology)
			setIdeologyS3Url(data.tenantProperties.ideologyS3Url)
			setShowGuides(data.tenantProperties.showGuides)
			setGuidesS3Url(data.tenantProperties.guidesS3Url)
			setShowManagerInduction(data.tenantProperties.showManagerInduction)
			setManagerInductionS3Url(data.tenantProperties.managerInductionS3Url)
			setShowMessages(data.tenantProperties.showMessages)
			setShowCategoryStats(data.tenantProperties.showCategoryStats)
			setShowBackgroundImages(data.tenantProperties.showBackgroundImages)
			setMakeHeadersDark(data.tenantProperties.makeHeadersDark);

			setIsLoading(false);
			if (!data.success) {
				notify(pleaseRefreshError);
			}
		} catch (error) {
			console.log(error);
		}
	};
	fetchTenantSettingsData();
}, []);

  const customizeLykioHandler = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("logo", logo);
	  formData.append("background", background);
      formData.append("themeName", themeName);
      formData.append("primaryColor", primaryColor);
      formData.append("primaryColorAccent", primaryColorAccent);
      formData.append("secondaryColor", secondaryColor);
      formData.append("secondaryColorAccent", secondaryColorAccent);
      formData.append("backgroundColor", backgroundColor);
      formData.append("formBackgroundColor", formBackgroundColor);
      formData.append("activeColor", activeColor);
      formData.append("primaryTextColor", primaryTextColor);
      formData.append("reversedTextColor", reversedTextColor);
      formData.append("specialTextColor", specialTextColor);
      formData.append("mutedTextColor", mutedTextColor);
	  formData.append("enableUsersCanPickTheme", enableUsersCanPickTheme);
      formData.append("enableUploadForOtherUsers", enableUploadForOtherUsers);
	  formData.append("enableDefaultAwards", enableDefaultAwards);
	  formData.append("initiative", initiative);
	  formData.append("welcomeMessage",welcomeMessage);
      formData.append("storiesName", storiesName);
	  formData.append("groupName", groupName);
	  formData.append("storiesTagLine", storiesTagLine);
      formData.append("categoriesName", categoriesName);
	  formData.append("rootCategoriesName", rootCategoriesName);
	  formData.append("showUserGuidesInMainNav", showUserGuidesInMainNav);
      formData.append("showLocalLeaderboards", showLocalLeaderboards);
      formData.append("showGroupLeaderboards", showGroupLeaderboards);
      formData.append("showGroupsInLeaderboards", showGroupsInLeaderboards);
      formData.append("showIdeology", showIdeology);
	  formData.append("ideologyS3Url", ideologyS3Url);
      formData.append("showGuides", showGuides);
	  formData.append("guidesS3Url", guidesS3Url);
	  formData.append("showManagerInduction", showManagerInduction);
	  formData.append("managerInductionS3Url", managerInductionS3Url);
      formData.append("showMessages", showMessages);
      formData.append("showCategoryStats", showCategoryStats);
	  formData.append('showBackgroundImages',showBackgroundImages);
	  formData.append('makeHeadersDark',makeHeadersDark);
	  
      console.log(formData);
	  console.log("enableDefaultAwards:", enableDefaultAwards);
	  console.log("tenantProperties.enableDefaultAwards.....",tenantProperties.enableDefaultAwards);
      const { data } = await axios.post(
        `${AUTHORIZED_URL_PREFIX}${CUSTOMIZE_LYKIO_URL}`,
        formData,
        AxiosConfig("multipart/form-data")
      );
      if (data.success === true) {
        notify({
          title: `${t("You have successfully updated your settings for all your users.")}`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        });
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      notify(pleaseRefreshError);
    }
  };

  const handleThemeChange = (e) => {
    setThemeName(e.target.value);
    let selectedTheme = e.target.value;
	let themesResults = [];
    if (selectedTheme === "Default") {
		themesResults = themes.filter(theme => {
			return theme.name === "Default"
		})
    }
	if (selectedTheme === "PearlRiver") {
		themesResults = themes.filter(theme => {
			return theme.name === "PearlRiver"
		})
    }
	if (selectedTheme === "ViceroyGold") {
		themesResults = themes.filter(theme => {
			return theme.name === "ViceroyGold"
		})
	}
    if (selectedTheme === "GhostWhite") {
		themesResults = themes.filter(theme => {
			return theme.name === "GhostWhite"
		})
    }
	if(themesResults.length>0){
		let themeProperties = themesResults[0].properties;
		setThemeName(themesResults[0].name);
		setPrimaryColor(themeProperties.primaryColor);
		setPrimaryColorAccent(themeProperties.primaryColorAccent);
		setSecondaryColor(themeProperties.secondaryColor);
		setSecondaryColorAccent(themeProperties.secondaryColorAccent);
		setBackgroundColor(themeProperties.backgroundColor);
		setFormBackgroundColor(themeProperties.formBackgroundColor);
		setActiveColor(themeProperties.activeColor);
		setPrimaryTextColor(themeProperties.primaryTextColor);
		setReversedTextColor(themeProperties.reversedTextColor);
		setSpecialTextColor(themeProperties.specialTextColor);
		setMutedTextColor(themeProperties.mutedTextColor);
	}
  };

  return (
    <div className="lykioccs-main-content">
      {isLoading ? (
        <Loading />
      ) : (
		<Container className="lykiocss-margin-top-2em">
		<Form
			  onSubmit={customizeLykioHandler}
			  className="lykiocss-form lykiocss-form-border lykiocss-form-size"
			>
			  <div className="lykiocss-center lykiocss-title-light">
				{t("Customize Lykio")}
			  </div>
			  <h7 className="lykiocss-center">
				  {t("(changes apply to all users)")}
			  </h7>
			  <br />
			  <Container>
				<Row>
				  <Col>
					<Form.Label>{t("My Company Logo")}</Form.Label>
					<br />
					<Form.Group controlId="formFile" className="mb-3">
					  <Form.Control
						filename={logo}
						accept="image/*"
						type="file"
						onChange={(e) => setLogo(e.target.files[0])}
					  />
					</Form.Group>

					<Form.Group className="mb-3" controlId="group">
					<FloatingLabel controlId="floatingtheme" label={t(`My Theme`)}>
					  <Form.Select onChange={handleThemeChange}>
						{themes.map((theme, i) => (
						  <option
							selected={theme.name === themeName}
							value={theme.name}
						  >
							{theme.name}
						  </option>
						))}
					  </Form.Select>
					  </FloatingLabel>
					</Form.Group>

					<Form.Label>{t("My Background")}</Form.Label>
					<br />
					<Form.Group controlId="formFile" className="mb-3">
					  <Form.Control
						filename={logo}
						accept="image/*"
						type="file"
						onChange={(e) => setBackground(e.target.files[0])}
					  />
					</Form.Group>

					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setShowBackgroundImages(!showBackgroundImages);
						}}
						checked={showBackgroundImages}
						type="switch"
						id="showBackgroundImages"
						label={t("Show background image in selected theme")}
					  />
					</Form.Group>

					
					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setMakeHeadersDark(!makeHeadersDark);
						}}
						checked={makeHeadersDark}
						type="switch"
						id="makeHeadersDark"
						label={t("Enforce dark headers to fit your light-coloured background")}
					  />
					</Form.Group>
	
					<Form.Group className="mb-3" controlId="initiative">
					  <FloatingLabel controlId="floatinginitiative" label={t(`Your initiative's name`)}>
					  <Form.Control
						type="text"
						maxLength={25}
						defaultValue={initiative}
						onChange={(e) => {
						  setInitiative(e.target.value);
						}}
					  />
					  </FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="welcome">
					  <FloatingLabel controlId="floatingwelcome" label={t(`The welcome message for your users`)}>
					  <Form.Control
						type="text"
						defaultValue={welcomeMessage}
						onChange={(e) => {
						  setWelcomeMessage(e.target.value);
						}}
					  />
					  </FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="groupName">
					  <FloatingLabel controlId="floatinggn" label={t(`What a group is called in your organization`)}>
					  <Form.Control
						type="text"
						defaultValue={groupName}
						maxLength={25}
						onChange={(e) => {
						  setGroupName(e.target.value);
						}}
					  />
					  </FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="storiesName">
					  <FloatingLabel controlId="floatingstories" label={t(`What user stories are currently called`)}>
					  <Form.Control
						type="text"
						defaultValue={storiesName}
						maxLength={25}
						onChange={(e) => {
						  setStoriesName(e.target.value);
						}}
					  />
					  </FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="storiesTagLine">
					  <FloatingLabel controlId="floatingstoriestag" label={t(`Tagline displayed in the Stories page`)}>
					  <Form.Control
						type="text"
						defaultValue={storiesTagLine}
						maxLength={50}
						onChange={(e) => {
						  setStoriesTagLine(e.target.value);
						}}
					  />
					  </FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="description">
					<FloatingLabel controlId="floatingcats" label={t(`What categories are currently called`)}>
					  <Form.Control
						type="text"
						placeholder={categoriesName}
						defaultValue={categoriesName}
						maxLength={20}
						onChange={(e) => {
						  setCategoriesName(e.target.value);
						}}
					  />
					</FloatingLabel>
					</Form.Group>

					<Form.Group className="mb-3" controlId="description">
					<FloatingLabel controlId="floatingrootcats" label={t(`What root categories are currently called`)}>
					  <Form.Control
						type="text"
						placeholder={rootCategoriesName}
						defaultValue={rootCategoriesName}
						onChange={(e) => {
						  setRootCategoriesName(e.target.value);
						}}
					  />
					</FloatingLabel>
					</Form.Group>
				  </Col>
	
				  <Col className="d-flex align-items-center">
					<div>
					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setEnableUsersCanPickTheme(!enableUsersCanPickTheme);
						}}
						checked={enableUsersCanPickTheme}
						type="switch"
						id="enableUsersCanPickTheme"
						label={t("Users can pick their own theme")}
					  />
					</Form.Group>	

					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setEnableUploadForOtherUsers(!enableUploadForOtherUsers);
						}}
						checked={enableUploadForOtherUsers}
						type="switch"
						id="enableUploadForOtherUsers"
						label={t("Users can upload on behalf of others")}
					  />
					</Form.Group>					
	
					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setShowLocalLeaderboards(!showLocalLeaderboards);
						}}
						checked={showLocalLeaderboards}
						type="switch"
						id="showLocalLeaderboards"
						label={t("Show Local Leaderboards")}
					  />
					</Form.Group>
	
					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setShowGroupLeaderboards(!showGroupLeaderboards);
						}}
						checked={showGroupLeaderboards}
						type="switch"
						id="showGroupLeaderboards"
						label={t("Show Group Leaderboards")}
					  />
					</Form.Group>
	
					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setShowGroupsInLeaderboards(!showGroupsInLeaderboards);
						}}
						checked={showGroupsInLeaderboards}
						type="switch"
						id="showGroupsInLeaderboards"
						label={t("Show Groups In Leaderboards")}
					  />
					</Form.Group>
	
					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setShowIdeology(!showIdeology);
						}}
						checked={showIdeology}
						type="switch"
						id="showIdeology"
						label={t("Show the Ideology Page")}
					  />
					</Form.Group>

					<Form.Group className="mb-3" controlId="ideologyS3Url">
					  <FloatingLabel controlId="floatingideo" label={t(`Your ideology page url`)}>
					  <Form.Control
						type="text"
						defaultValue={ideologyS3Url}
						onChange={(e) => {
						  setIdeologyS3Url(e.target.value);
						}}
					  />
					  </FloatingLabel>
					</Form.Group>
	
					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setShowGuides(!showGuides);
						}}
						checked={showGuides}
						type="switch"
						id="showGuides"
						label={t("Show The Guides Page")}
					  />
					</Form.Group>
					

					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setShowUserGuidesInMainNav(!showUserGuidesInMainNav);
						}}
						checked={showUserGuidesInMainNav}
						type="switch"
						id="showUserGuidesInMainNav"
						label={t("Show The Guides Page In Navbar")}
					  />
					</Form.Group>

					<Form.Group className="mb-3" controlId="guidesS3Url">
					  <FloatingLabel controlId="floatingguides" label={t(`Your guides page url`)}>
					  <Form.Control
						type="text"
						defaultValue={guidesS3Url}
						onChange={(e) => {
						  setGuidesS3Url(e.target.value);
						}}
					  />
					  </FloatingLabel>
					</Form.Group>

					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setShowManagerInduction(!showManagerInduction);
						}}
						checked={showManagerInduction}
						type="switch"
						id="showManagerInduction"
						label={t("Show The Manager Induction")}
					  />
					</Form.Group>

					<Form.Group className="mb-3" controlId="managerS3Url">
					  <FloatingLabel controlId="floatingmanager" label={t(`Your manager induction url`)}>
					  <Form.Control
						type="text"
						defaultValue={managerInductionS3Url}
						onChange={(e) => {
						  setManagerInductionS3Url(e.target.value);
						}}
					  />
					  </FloatingLabel>
					</Form.Group>
	
					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setShowMessages(!showMessages);
						}}
						checked={showMessages}
						type="switch"
						id="showMessages"
						label={t("Show The Messages Page")}
					  />
					</Form.Group>
	
					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setShowCategoryStats(!showCategoryStats);
						}}
						checked={showCategoryStats}
						type="switch"
						id="showCategoryStats"
						label={t("Show Stats In Categories")}
					  />
					</Form.Group>

					<Form.Group className="lykiocss-margin-bottom-half-em">
					  <Form.Check
						onChange={() => {
						  setEnableDefaultAwards(!enableDefaultAwards);
						}}
						checked={enableDefaultAwards}
						type="switch"
						id="enableDefaultAwards"
						label={t("Enable the default set of awards (you can always add your own)")}
					  />
					  <Tooltip title={t('You can view and enable the default set of awards from the Customize Lykio screen')}>
						  <Button variant='text' onClick={()=>{handleShow()}}>{t(`Preview Default Awards`)}</Button>
					  </Tooltip>
					</Form.Group>

					<Form.Group className="lykiocss-margin-top-2em">
						<LykioButton
						variant="contained"
						type="text"
						className="float-end lykiocss-form-button">
						{t("Update")}
						</LykioButton>
					</Form.Group>
					</div>
				  </Col>
				</Row>
			  </Container>
			</Form>

		  <Modal size="lg" centered show={show} onHide={handleClose} closeButton>
          <Modal.Header closeButton>
          <h4>{t("Default Set Of Awards")}</h4>
          </Modal.Header>
          <Modal.Body>
		  <ProfileAwards awards={GM_INITIAL_BADGES_SET} />
          </Modal.Body>
        </Modal>
		</Container>
	  )}
    </div>
  )  
};

export default CustomizeLykio;
