import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./verifyAccountScreen.css";
import {
  HOME_URL,
  FRONTEND_LOGIN_URL,
  PUBLIC_URL_PREFIX,
  UBER_ADMIN_SETTINGS_URL,
  VERIFYACCOUNT_URL,
  NOTIFICATION_SEVERITY_SUCCESS,
  AxiosConfigNoAuth,
} from "../../Utils/Constants";
import t from "../../Utils/Translation";
import lykio_logo from "../../multimedia/Default/white_loading.gif";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { notify, pleaseRefreshError } from "../../Utils/Notificator";

const VerifyAccountScreen = () => {
  const [authScreensLogo, setAuthScreensLogo] = useState();
  const navigate = useNavigate();
  const { verificationToken } = useParams();
  
  const questions = [
    {
      id: "q1", label: "1. How important do you think it is for leaders to adapt their skills to effectively manage remote or hybrid teams in the post-pandemic world?", 
      type: "range", min: 1, max: 5, labelMin: "1 - Little", labelMax: "5 - Very",
    },
    {
      id: "q2", label: "2. Are you interested in learning about the specific challenges and opportunities of leading virtual teams, particularly hybrid teams?",  
      type: "radio",
      options: [
        { value: 1, label: "Yes" }, { value: 0, label: "No" },
      ],
    },
    {
      id: "q3", label: "3. How interested are you in learning new skills and knowledge to adapt to the changing work environment, where remote and hybrid work have become more prevalent after the pandemic?", 
      type: "range", min: 1, max: 5, labelMin: "1 - Little", labelMax: "5 - Very",
    },
    {
      id: "q4", label: "4. Do you think it's important for trainers to have a strong understanding of digital tools and online learning methodologies for training remote teams effectively?",  
      type: "radio",
      options: [
        { value: 1, label: "Yes" }, { value: 0, label: "No" },
      ],
    },
    {
      id: "q5", label: "5. How interested are you in learning about tools and strategies to improve communication and teamwork in a remote or hybrid teaching environment?", 
      type: "range", min: 1, max: 5, labelMin: "1 - Little", labelMax: "5 - Very",
    },
    {
      id: "q6", label: "6. Do you believe that professional schools and VET providers could benefit from embracing the advantages of remote and hybrid teaching methods?",  
      type: "radio",
      options: [
        { value: 1, label: "Yes" }, { value: 0, label: "No" },
      ],
    },
    {
      id: "q7", label: "7. How interested are you in increasing your digital literacy and critical thinking skills for research, use, and creation of digital content in hybrid business environments?", 
      type: "range", min: 1, max: 5, labelMin: "1 - Little", labelMax: "5 - Very",
    },
    {
      id: "q8", label: "8. Do you believe that effective research strategies for finding, evaluating, and using digital content are crucial for thriving in digitally transformed businesses?",  
      type: "radio",
      options: [
        { value: 1, label: "Yes" }, { value: 0, label: "No" },
      ],
    },
    {
      id: "q9", label: "9. How interested are you in learning about strategies for positively adapting to remote or hybrid work modes and maintaining productivity?", 
      type: "range", min: 1, max: 5, labelMin: "1 - Little", labelMax: "5 - Very",
    },
    {
      id: "q10", label: "10. Do you believe that effective time management techniques and skills are crucial for success in remote and hybrid work environments?",  
      type: "radio",
      options: [
        { value: 1, label: "Yes" }, { value: 0, label: "No" },
      ],
    },
  ];
  const [q1, setQ1] = useState(1);
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(1);
  const [q4, setQ4] = useState(null);
  const [q5, setQ5] = useState(1);
  const [q6, setQ6] = useState(null);
  const [q7, setQ7] = useState(1);
  const [q8, setQ8] = useState(null);
  const [q9, setQ9] = useState(1);
  const [q10, setQ10] = useState(null);
  const [values, setValues] = useState({
    q1: 1,
    q2: null,
    q3: 1,
    q4: null,
    q5: 1,
    q6: null,
    q7: 1,
    q8: null,
    q9: 1,
    q10: null,
  });
  const [answeredAll, setAnsweredAll] = useState(false);
  const [canVerifyAccount, setCanVerifyAccount] = useState(false);
  //http://localhost:3000/verifyaccount/6453794d853a0d3244f85afe



  useEffect(async () => {
    const { data } = await axios.get(
      `${PUBLIC_URL_PREFIX}${UBER_ADMIN_SETTINGS_URL}`,
      AxiosConfigNoAuth()
    );
    data.uberSettings && setAuthScreensLogo(data.uberSettings.authScreensLogo);
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    let newValue;
  
    if (type === "radio") {
      newValue = parseInt(value);
      setValues((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    } else {
      newValue = type === "range" ? parseInt(value) : value;
      setValues((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }
    checkIfAnsweredAll();
  };

  useEffect(() => {
    checkIfAnsweredAll();
  }, [values]);

  const checkIfAnsweredAll = () => {
    const allAnswered = Object.keys(values).every((key) => values[key] !== null);
    setAnsweredAll(allAnswered);
  };
  

  const handleFinishAnswers = () => {
    if (answeredAll) {
      setCanVerifyAccount(true);
    }
   };

  const verifyAccountHandler = async (e) => {
    // stop the form from submitting
    e.preventDefault();

    try {
      const { data } = await axios.post(
        VERIFYACCOUNT_URL,
        { verificationToken, values },
        AxiosConfigNoAuth()
      );
      if (data.success) {
        notify({
          title: `${t(
            "You have successfully verified your account. You can now login."
          )}`,
          severity: NOTIFICATION_SEVERITY_SUCCESS,
          creationDate: "",
        });
      }
      navigate(FRONTEND_LOGIN_URL);
    } catch (error) {
      notify(pleaseRefreshError);
    }
  };

  return (
    <div className="lykiocss-generic-background lykiocss-forms-container">
      <div className="lykiocss-screen-center">
        <Form
          onSubmit={verifyAccountHandler}
          className="LykioForm lykiocss-white-background lykiocss-login-form-border lykiocss-form-size"
        >
          <Link to={HOME_URL}>
            <div className="lykiocss-center">
              <img
                className="lykiocss-small-login-logo"
                src={authScreensLogo ? authScreensLogo : lykio_logo}
                alt=""
              />
            </div>
          </Link>
          <h3 className="verifyaccount-screen__title">
            {t("Account Verification")}
          </h3>
          {canVerifyAccount ? <Button type="submit" className="btn btn-primary">
            {t("Click to verify your account")}
          </Button>:
          <div className="verifyaccount-questionnaire">
            <div style={{textAlign:'center'}}>
              <p>{t("Please complete the following questionnaire and press the 'Submit' button.")}</p>
            </div>
            <div className="verifyaccount-question">
              {questions.map((question) => (
                <div key={question.id}>
                  <label htmlFor={question.id}>{question.label}</label>
                  {question.type === "range" && (
                    <>
                      <input
                        type="range"
                        id={question.id}
                        name={question.id}
                        min={question.min}
                        max={question.max}
                        step="1"
                        value={values[question.id] || "1"}
                        onChange={handleInputChange}
                        list={`${question.id}-range`}
                        style={{ width: "100%" }}
                      />
                      <datalist id={`${question.id}-range`} 
                      style={{display:'flex', width: "98.7%", justifyContent:'space-between', 
                      marginLeft:'0.2em', marginBottom:'2em'}}>
                        {[...Array(question.max - question.min + 1)].map((_, i) => (
                          <option
                            key={`${question.id}-${i + question.min}`}
                            value={i + question.min}
                            label={i + question.min}
                          />
                        ))}
                      </datalist>
                    </>
                  )}
                  {question.type === "radio" && (
                    <div className="verifyaccount-question">
                      <div className="verifyaccount-yes-no-container">
                      {question.options.map((option) => (
                        <div key={`${question.id}-${option.value}`}>
                          <input
                            type="radio"
                            id={`${question.id}-${option.value}`}
                            name={question.id}
                            value={option.value}
                            checked={values[question.id] === option.value}
                            onChange={handleInputChange}
                          />
                          <label htmlFor={`${question.id}-${option.value}`}>
                            {option.label}
                          </label>
                        </div>
                      ))}
                      </div>
                    </div>
                  )}
                </div>
                ))}
                <div className="verifyaccount-submit-button">
                  <Button onClick={handleFinishAnswers}>{t("Submit")}</Button>
                </div>
            </div>
          </div>
          }
        </Form>
      </div>
    </div>
  );
};

export default VerifyAccountScreen;

